export class CBChallenges {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "CBCHALLENGES";
    this.scene = "CBCHALLENGES";
    this.data = [];
    this.page = 7058;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      if (options.push_up) {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "1";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-312 0 0";
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "0";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-343 0 0";
      }
      xml.payload.field.find((item) => {
        return item.attr.name === "00101";
      }).value =
        options.team?.variables?.alt_badge || options.team?.variables?.badge;

      xml.payload.field.find((item) => {
        return item.attr.name === "003";
      }).value =
        options.team?.short_name?.toUpperCase() ??
        options.team?.name?.toUpperCase();

      xml.payload.field.find((item) => {
        return item.attr.name === "015";
      }).value = options.successful;

      xml.payload.field.find((item) => {
        return item.attr.name === "025";
      }).value = options.unsuccessful;

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
