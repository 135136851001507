import { getApolloContext } from "@apollo/client";
import gql from "graphql-tag";
import React, { useState, useEffect, useContext } from "react";

const TEAMS = gql`
  query Teams {
    teams {
      _id
      name
      short_name
      code
      variables
      opta_ID
      oval_ID
    }
  }
`;

const COMPETITIONS = gql`
  query Competitions {
    competitions {
      _id
      name
      description
      opta_ID
      formation
      code
      country
      country_code
      competition_type
    }
  }
`;

const GAMES = gql`
  query Games {
    games {
      oval_ID
      _id
      time
      description
      location
      attendance
      date
      round
      home_interchanges_total
      away_interchanges_total
      home_interchanges_total_gp
      away_interchanges_total_gp
      home_challenges
      away_challenges
      competition {
        _id
        oval_ID
        opta_ID
        name
        competition_type
        badge
        secondary_badge
        oval_ID
        variables
      }
      home_team {
        _id
        name
        short_name
        opta_ID
        code
        rugby_league_ID
      }
      away_team {
        _id
        name
        short_name
        opta_ID
        code
        rugby_league_ID
      }
      live_data
      win_predictor
    }
  }
`;

const GAMES_BY_DATE = gql`
  query Games($date: String) {
    gamesByDate(date: $date) {
      _id
      time
      description
      location
      attendance
      date
      round
      stadium {
        opta_name
        name
        location
      }
      home_interchanges_total
      away_interchanges_total
      home_interchanges_total_gp
      away_interchanges_total_gp
      home_challenges
      away_challenges
      competition {
        _id
        oval_ID
        opta_ID
        name
        competition_type
        badge
        secondary_badge
        variables
      }
      lineup {
        home {
          squad {
            _id
            shirt_number
            opta_shirt_number
            position
            stats
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
                code
              }
              image
              stories
              oval_ID
              opta_ID
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
          yellow_card_time
          red_card_time
          red_card_seconds
          yellow_card_period
          red_card_period
          sinbins
        }
        away {
          squad {
            _id
            shirt_number
            opta_shirt_number
            position
            stats
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper

              nationality {
                _id
                opta_code
                opta_name
                code
              }
              image
              stories
              oval_ID
              opta_ID
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
          yellow_card_time
          red_card_time
          red_card_seconds
          yellow_card_period
          red_card_period
          sinbins
        }
      }
      opta_lineup {
        home {
          squad {
            _id
            opta_shirt_number
            stats
            player {
              first_name
              last_name
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            stats
            player {
              first_name
              last_name
            }
          }
          order
        }
      }
      home_team {
        _id
        name
        short_name
        opta_ID
        code
        rugby_league_ID
        variables
      }
      away_team {
        _id
        name
        short_name
        opta_ID
        code
        rugby_league_ID
        variables
      }
      live_data
      win_predictor
      officials {
        type
        official {
          first_name
          last_name
        }
      }
      opta_scores
      oval_scores
      oval_status
    }
  }
`;

const GAMES_BY_ROUND = gql`
  query Games($round: String) {
    gamesByRound(round: $round) {
      _id
      time
      description
      location
      attendance
      date
      round
      stadium {
        opta_name
        name
        location
      }
      home_interchanges_total
      away_interchanges_total
      home_interchanges_total_gp
      away_interchanges_total_gp
      home_challenges
      away_challenges
      competition {
        _id
        oval_ID
        opta_ID
        name
        competition_type
        badge
        secondary_badge
        variables
      }
      lineup {
        home {
          squad {
            _id
            shirt_number
            opta_shirt_number
            position
            stats
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
                code
              }
              image
              stories
              oval_ID
              opta_ID
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
          yellow_card_time
          red_card_time
          red_card_seconds
          yellow_card_period
          red_card_period
          sinbins
        }
        away {
          squad {
            _id
            shirt_number
            opta_shirt_number
            position
            stats
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper

              nationality {
                _id
                opta_code
                opta_name
                code
              }
              image
              stories
              oval_ID
              opta_ID
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
          yellow_card_time
          red_card_time
          red_card_seconds
          yellow_card_period
          red_card_period
          sinbins
        }
      }
      opta_lineup {
        home {
          squad {
            _id
            opta_shirt_number
            stats
            player {
              first_name
              last_name
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            stats
            player {
              first_name
              last_name
            }
          }
          order
        }
      }
      home_team {
        _id
        name
        short_name
        opta_ID
        code
        rugby_league_ID
        variables
      }
      away_team {
        _id
        name
        short_name
        opta_ID
        code
        rugby_league_ID
        variables
      }
      live_data
      win_predictor
      officials {
        type
        official {
          first_name
          last_name
        }
      }
      opta_scores
      oval_scores
      oval_status
      home_team_stats
      away_team_stats
    }
  }
`;
const SCORES = gql`
  query GameScores($date: String) {
    gamesScoresByDate(date: $date) {
      _id
      competition {
        _id
        oval_ID
        opta_ID
        name
        competition_type
        badge
        secondary_badge
        variables
      }
      season {
        _id
      }
      lineup {
        home {
          squad {
            _id
            shirt_number
            opta_shirt_number
            position
            stats
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
                code
              }
              image
              stories
              oval_ID
              opta_ID
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
          yellow_card_time
          red_card_time
          red_card_seconds
          yellow_card_period
          red_card_period
          sinbins
        }
        away {
          squad {
            _id
            shirt_number
            opta_shirt_number
            position
            stats
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper

              nationality {
                _id
                opta_code
                opta_name
                code
              }
              image
              stories
              oval_ID
              opta_ID
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
          yellow_card_time
          red_card_time
          red_card_seconds
          yellow_card_period
          red_card_period
          sinbins
        }
      }
      home_team {
        _id
        name
        short_name
        opta_ID
        code
        rugby_league_ID
        variables
      }
      away_team {
        _id
        name
        short_name
        opta_ID
        code
        rugby_league_ID
        variables
      }
      opta_scores
    }
  }
`;

const UPDATE_GAME = gql`
  mutation UpdateGame(
    $id: String!
    $live_data: JSON
    $lineup: JSON
    $home_scorers: JSON
    $away_scorers: JSON
    $home_penalties: JSON
    $away_penalties: JSON
    $home_phase: Int
    $away_phase: Int
    $metres_gained: Int
    $home_player_stats: JSON
    $away_player_stats: JSON
    $home_team_stats: JSON
    $away_team_stats: JSON
    $home_sets: JSON
    $away_sets: JSON
    $attendance: String
    $home_challenges: JSON
    $away_challenges: JSON
  ) {
    updateGame(
      id: $id
      live_data: $live_data
      lineup: $lineup
      home_scorers: $home_scorers
      away_scorers: $away_scorers
      home_penalties: $home_penalties
      away_penalties: $away_penalties
      home_phase: $home_phase
      away_phase: $away_phase
      metres_gained: $metres_gained
      home_player_stats: $home_player_stats
      away_player_stats: $away_player_stats
      home_team_stats: $home_team_stats
      away_team_stats: $away_team_stats
      home_sets: $home_sets
      away_sets: $away_sets
      attendance: $attendance
      home_challenges: $home_challenges
      away_challenges: $away_challenges
    ) {
      live_data
    }
  }
`;

const SQUAD_BY_TEAM = gql`
  query Squad($teamId: String, $seasonId: String) {
    squadByTeam(teamId: $teamId, seasonId: $seasonId) {
      _id
      active
      type
      shirt_number
      opta_shirt_number
      position
      player {
        _id
        opta_ID
        first_name
        last_name
        first_name_upper
        last_name_upper
        title
        nationality {
          _id
          opta_code
          opta_name
          code
        }
        image
        stories
        opta_ID
      }
      team {
        name
        short_name
      }
    }
  }
`;

const STANDINGS = gql`
  query Standings {
    standings {
      _id
      oval_comp_ID
      oval_season_ID
      opta_comp_ID
      opta_season_ID
      data
      date
    }
  }
`;
const STANDINGS_START_OF_DAY = gql`
  query Standings {
    startOfDayStandings {
      _id
      oval_comp_ID
      oval_season_ID
      opta_comp_ID
      opta_season_ID
      data
      date
    }
  }
`;

const UPDATE_STANDINGS_START_OF_DAY = gql`
  mutation UpdateStandings($id: String!, $data: JSON) {
    updateStartOfDayStanding(id: $id, data: $data) {
      _id
      date
    }
  }
`;

const PUNDITS = gql`
  query Pundits {
    pundits {
      _id
      first_name
      last_name
      first_name_upper
      last_name_upper
      group
      stories
      ss_logo
      badge
    }
  }
`;

const SEASON_STATS = gql`
  query SeasonStats($id: String!) {
    seasonStatRankingsBySeason(seasonId: $id) {
      _id
      opta_player_stats {
        type
        players {
          value
          rank
          squad {
            player {
              first_name
              last_name
            }
          }
          team {
            name
            code
            variables
          }
        }
      }
    }
  }
`;

const CORNER_BOXES = gql`
  query CornerBoxes {
    cornerboxes {
      _id
      name
      number
      type
      number_rows
      number_columns
      text_type
      title_badge
      heading
      subtitle
      column_1
      column_2
      column_3
      column_4
      rows
      show_badges
      title_badge_folder
      game {
        _id
      }
    }
  }
`;

const APIContext = React.createContext([{}, () => {}]);

const APIProvider = (props) => {
  const { client } = useContext(getApolloContext());
  let serverAddress = window.dataUrl.replace("ws", "http");

  if (serverAddress.slice(-1) === "/") {
    serverAddress = serverAddress.slice(0, -1);
  }

  function getCornerBoxes() {
    return client
      .query({
        query: CORNER_BOXES,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.cornerboxes;
      })
      .catch((err) => console.error(err));
  }

  function getSeason(seasonId) {
    return fetch(serverAddress + "/seasons/" + (seasonId ? seasonId : ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }
  function getOfficial(officialId) {
    return fetch(serverAddress + "/officials/" + (officialId ? officialId : ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }
  function getStadium(stadiumId) {
    return fetch(serverAddress + "/stadiums/" + (stadiumId ? stadiumId : ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getStandings() {
    return client
      .query({
        query: STANDINGS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => console.error(err));
  }

  function getStartOfDayStandings() {
    return client
      .query({
        query: STANDINGS_START_OF_DAY,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => console.error(err));
  }

  function updateStartOfDayStandings({ id, data }) {
    return client
      .mutate({
        mutation: UPDATE_STANDINGS_START_OF_DAY,
        variables: {
          id,
          data,
        },
      })
      .then((response) => {
        return response.data.updateStartOfDayStanding;
      })
      .catch((err) => console.error(err));
  }
  function getCompetition(compId) {
    return client
      .query({
        query: COMPETITIONS,
      })
      .then((response) => {
        return response.data.competitions;
      })
      .catch((err) => console.error(err));
  }

  function updatePlayer(player) {
    return fetch(serverAddress + "/players/" + player._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(player),
    });
  }
  function updateLineup({ data, fixture, team }) {
    return fetch(serverAddress + "/games/" + fixture._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { [`live_data.${team}_team_lineup`]: data },
      }),
    });
  }

  function getSquads() {
    return fetch(serverAddress + "/squads/")
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getTeams({ team }) {
    return client
      .query({
        query: TEAMS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.teams;
      })
      .catch((err) => console.error(err));
  }

  function getFixture({ fixtureId }) {
    return client
      .query({
        query: GAMES,
      })
      .then((response) => {
        return response.data.games.find((game) => game._id === fixtureId);
      })
      .catch((err) => console.error(err));
  }

  function getFixtures({ competition, date }) {
    return client
      .query({
        query: GAMES_BY_DATE,
        variables: { date: date + "Z" },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.gamesByDate;
      })
      .catch((err) => console.error(err));
  }
  function getFixturesByRound({ competition, round }) {
    return client
      .query({
        query: GAMES_BY_ROUND,
        variables: { round: round },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.gamesByRound;
      })
      .catch((err) => console.error(err));
  }

  function getScores({ competition, date }) {
    return client
      .query({
        query: SCORES,
        variables: { date: date + "Z" },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.gamesScoresByDate;
      })
      .catch((err) => console.error(err));
  }

  function updateGame({
    id,
    live_data,
    lineup,
    home_scorers,
    away_scorers,
    home_penalties,
    away_penalties,
    home_phase,
    away_phase,
    metres_gained,
    home_player_stats,
    away_player_stats,
    home_team_stats,
    away_team_stats,
    home_sets,
    away_sets,
    attendance,
    home_challenges,
    away_challenges,
  }) {
    return client
      .mutate({
        mutation: UPDATE_GAME,
        variables: {
          id,
          live_data,
          lineup,
          home_scorers,
          away_scorers,
          home_penalties,
          away_penalties,
          home_phase,
          away_phase,
          metres_gained,
          home_player_stats,
          away_player_stats,
          home_sets,
          away_sets,
          attendance,
          home_team_stats,
          away_team_stats,
          home_challenges,
          away_challenges,
        },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function getSquadPlayers({ teamId, seasonId, coach }) {
    return client
      .query({
        query: SQUAD_BY_TEAM,
        fetchPolicy: "network-only",
        variables: {
          teamId: teamId,
          seasonId: seasonId,
        },
      })
      .then((response) => {
        return response.data.squadByTeam;
      })
      .catch((err) => console.error(err));
  }

  function getSquad({ teamId, seasonId }) {
    return getSquadPlayers({ teamId: teamId, seasonId: seasonId });
  }

  function getLineup({ fixtureId, team }) {
    return getFixture({ fixtureId: fixtureId }).then((fixture) => {
      let team_lineup = fixture.live_data[`${team}_team_lineup`];
      let url = "";

      return getSquadPlayers({
        teamId: fixture[team + "_team"],
        seasonId: fixture.season,
      }).then((squadPlayers) => {
        if (team_lineup && team_lineup.length > 1) {
          url =
            serverAddress +
            "/players/?$orderby=last_name&$filter=_id $in " +
            team_lineup.map((d) => d.player).join(",");
        } else if (team_lineup && team_lineup.length > 0) {
          url = serverAddress + "/players/" + team_lineup[0].player;
        }

        if (url === "") {
          return Promise.resolve();
        }

        return fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (Array.isArray(data)) {
              return team_lineup.map((l) => {
                return {
                  ...l,
                  ...squadPlayers.find((p) => p.player === l.player),
                  ...data.find((p) => p._id === l.player),
                  order: l.order,
                };
              });
            } else {
              return team_lineup.map((l) => {
                return { ...l, ...data, order: l.order };
              });
            }
          });
      });
    });
  }

  function getPlayersBySearch({ search, count, limit, page }) {
    let url =
      serverAddress + "/players?$filter=last_name $regex '^" + search + "'";
    if (count) {
      url += "&$count";
    }
    if (limit) {
      url += "&$limit=" + limit;
    }
    if (page) {
      url += "&$skip=" + page;
    }

    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function createFixture({ fixture }) {
    let newFixture = {
      ...fixture,
      live_data: { home_score: 0, away_score: 0 },
    };
    return fetch(serverAddress + "/games/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newFixture),
    });
  }

  function deleteFixture({ fixtureId }) {
    return fetch(serverAddress + "/games/" + fixtureId, {
      method: "DELETE",
    });
  }

  function getOfficial(officialId) {
    return fetch(serverAddress + "/officials/" + (officialId || ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function updateGameOfficial({ fixtureId, official, type }) {
    return fetch(serverAddress + "/games/" + fixtureId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { [type]: official._id },
      }),
    });
  }

  function updateHeadCoach({ teamId, coach }) {
    return fetch(serverAddress + "/teams/" + teamId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { coach: coach },
      }),
    });
  }
  function getPlayer({ playerId }) {
    return fetch(serverAddress + "/players/" + (playerId || ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function updatePlayer(player) {
    if (player._id) {
      player = { ...player, nationality: player.nationality._id };
      return fetch(serverAddress + "/players/" + player._id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(player),
      });
    } else {
      return fetch(serverAddress + "/players/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(player),
      });
    }
  }

  function getPundits() {
    return client
      .query({
        query: PUNDITS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.pundits;
      })
      .catch((err) => console.error(err));
  }

  function getSeasonStatRankingsBySeason({ season_id }) {
    return client
      .query({
        query: SEASON_STATS,
        fetchPolicy: "network-only",
        variables: { id: season_id },
      })
      .then((response) => {
        return response.data.seasonStatRankingsBySeason;
      })
      .catch((err) => console.error(err));
  }

  return (
    <APIContext.Provider
      value={{
        updatePlayer,
        updateLineup,
        getLineup,
        getFixture,
        getTeams,
        getSquad,
        getPlayersBySearch,
        getSquads,
        createFixture,
        deleteFixture,
        getOfficial,
        updateGameOfficial,
        updateHeadCoach,
        getCompetition,
        getSeason,
        getStadium,
        getOfficial,
        getPlayer,
        getFixtures,
        updatePlayer,
        updateGame,
        getStandings,
        getScores,
        getStartOfDayStandings,
        getPundits,
        updateStartOfDayStandings,
        getSeasonStatRankingsBySeason,
        getCornerBoxes,
        getFixturesByRound,
      }}
    >
      {props.children}
    </APIContext.Provider>
  );
};
export { APIContext, APIProvider };
