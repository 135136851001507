import React, { useCallback, useContext, useEffect, useState } from "react";
import Pitch from "../../../Images/pitch.jpg";
import styled from "styled-components";
import { Button, ScrollBar } from "../../../Theme/Hyper";
import { APIContext } from "../../../contexts/APIContext";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GraphicsContext } from "../../../contexts/GraphicsContext";
import { ControlContext } from "../../../contexts/ControlContext";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
let metres_timeout;
const LINEUP_FIELDS = gql`
  fragment LINEUP_FIELDS on LineupObject {
    home {
      squad {
        _id
        shirt_number
        opta_shirt_number
        position
        stats
        stories
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          short_name
          short_name_upper
          nationality {
            _id
            opta_code
            opta_name
            name
            code
          }
          image
          stories
          opta_ID
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      hia
      subbed
      captain
    }
    away {
      squad {
        _id
        shirt_number
        opta_shirt_number
        position
        stats
        stories
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          short_name
          short_name_upper
          nationality {
            _id
            opta_code
            opta_name
            name
            code
          }
          image
          stories
          opta_ID
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      hia
      subbed
      captain
    }
  }
`;
const GAME_BY_ID = gql`
  ${LINEUP_FIELDS}
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      time
      description
      date
      home_team_kit
      away_team_kit
      attendance
      stadium {
        opta_name
        name
      }
      competition {
        name
        competition_type
        badge
        secondary_badge
        variables
      }
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_player_stats
      away_player_stats
      home_team_stats
      away_team_stats
      opta_home_player_stats
      opta_away_player_stats
      opta_home_team_stats
      opta_away_team_stats
      lineup {
        ...LINEUP_FIELDS
      }
      home_team {
        _id
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        name
        short_name
        code
        variables
      }
      live_data
      home_phase
      away_phase
      metres_gained
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
      home_sets
      away_sets
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  ${LINEUP_FIELDS}
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      home_team_kit
      away_team_kit
      lineup {
        ...LINEUP_FIELDS
      }
      live_data
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_formation
      away_formation
      home_player_stats
      away_player_stats
      home_team_stats
      away_team_stats
      opta_home_player_stats
      opta_away_player_stats
      opta_home_team_stats
      opta_away_team_stats
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
      home_phase
      away_phase
      metres_gained
      home_sets
      away_sets
    }
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
  margin-top: 10px;
`;
const Row = styled.div`
  display: flex;
`;
const Team = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const PitchContainer = styled.div`
  position: relative;
`;

const Circle = styled.div`
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
  color: black;
`;

const TeamButton = styled(Button)`
  background-color: ${(props) => (props.selected ? "#db0a41" : "#181d25")};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SequenceColumn = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  align-items: center;
  width: 100%;
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
`;
const SequenceRow = styled(Row)`
  justify-content: center;
  width: 90%;
  border-bottom: 2px solid #202731;
  background-color: ${(props) => (props.selected ? "red" : "#1c222b")};
  font-size: 11px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

const TackleButton = styled(Button)`
  margin: 10px;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#353a47")};
`;
//let left_try_line = 82; //500px
//let right_try_line = 777;//500px
let left_try_line = 116; //500px
let right_try_line = 1090; //500px
let pixels_per_metre = (right_try_line - left_try_line) / 100;

function Tackles() {
  let { gameID, edit } = useParams();
  const [game, setGame] = useState();
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID);
  const { updateGame } = useContext(APIContext);
  const [tackles, setTackles] = useState({ team: "home", tackles: [] });
  const [sequence, setSequence] = useState([]);
  const [team, setTeam] = useState();
  const { update, animate, animateOff } = useContext(ControlContext);
  const { updateGraphic } = useContext(GraphicsContext);
  const [tackleNumber, setTackleNumber] = useState(-1);
  const [switched, setSwitched] = useState(false);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    let unsubscribe;

    if (subscribeToMore) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };

          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, gameID]);

  useEffect(() => {
    if (data) {
      setGame(data.gameById);
      if (sequence.length === 0) {
        let game = JSON.parse(JSON.stringify(data.gameById));
        setSequence([...(game?.home_sets || []), ...(game?.away_sets || [])]);
      }
      if (
        window.ENV?.REACT_APP_SKY !== "true" &&
        process.env.REACT_APP_SKY !== "true"
      ) {
        let try_points =
          data.gameById?.competition?.competition_type === "union" ? 5 : 4;
        let conversion_points =
          data.gameById?.competition?.competition_type === "union" ? 2 : 2;
        let penalty_kick_points =
          data.gameById?.competition?.competition_type === "union" ? 3 : 2;
        let penalty_try_points =
          data.gameById?.competition?.competition_type === "union" ? 7 : 4;
        let drop_goal_points =
          data.gameById?.competition?.competition_type === "union" ? 3 : 1;

        let home_score =
          (data.gameById?.home_scorers?.filter((g) => g.type === "try").length *
            try_points || 0) +
          (data.gameById?.home_scorers?.filter((g) => g.type === "conversion")
            .length * conversion_points || 0) +
          (data.gameById?.home_scorers?.filter((g) => g.type === "penalty_kick")
            .length * penalty_kick_points || 0) +
          (data.gameById?.home_scorers?.filter((g) => g.type === "penalty_try")
            .length * penalty_try_points || 0) +
          (data.gameById?.home_scorers?.filter((g) => g.type === "drop_goal")
            .length * drop_goal_points || 0);

        let away_score =
          (data.gameById?.away_scorers?.filter((g) => g.type === "try").length *
            try_points || 0) +
          (data.gameById?.away_scorers?.filter((g) => g.type === "conversion")
            .length * conversion_points || 0) +
          (data.gameById?.away_scorers?.filter((g) => g.type === "penalty_kick")
            .length * penalty_kick_points || 0) +
          (data.gameById?.away_scorers?.filter((g) => g.type === "penalty_try")
            .length * penalty_try_points || 0) +
          (data.gameById?.away_scorers?.filter((g) => g.type === "drop_goal")
            .length * drop_goal_points || 0);
        try {
          window.control_ws.send(
            JSON.stringify({
              action: "update",
              group: window.engines?.[1]?.key,
              engine: window.engines?.[1]?.key,
              data: {
                scene: "BS-Clock",
                data: [
                  {
                    name: "HomeScore",
                    value: {
                      text: home_score,
                    },
                  },
                  {
                    name: "AwayScore",
                    value: {
                      text: away_score,
                    },
                  },
                ],
              },
            })
          );
        } catch (err) {}
      }
    }
  }, [data]);

  function getTotalTackles(sequences, team) {
    let total = 0;
    sequences
      .filter((s) => s.team === team)
      .forEach((s) => {
        total += s?.tackles?.filter((s) => s.number > -1)?.length;
      });
    return total;
  }
  function getTotalMetres(sequences, team) {
    let total = 0;
    sequences
      .filter((s) => s.team === team)
      .forEach((s) => {
        total +=
          Math.abs(
            Math.round(
              (Math.max(
                left_try_line,
                Math.min(right_try_line, s?.tackles?.at(-1)?.x)
              ) -
                Math.max(
                  left_try_line,
                  Math.min(right_try_line, s?.tackles?.[0]?.x)
                )) /
                pixels_per_metre
            )
          ) || 0;
      });
    return total;
  }

  function getTotalTime(sequences, team) {
    let total = 0;
    sequences
      .filter((s) => s.team === team)
      .forEach((s) => {
        let sorted_tackles = s?.tackles?.sort((a, b) => a.number - b.number);
        let start_time = sorted_tackles[0]?.time || 0;
        let end_time = sorted_tackles?.at(-1)?.time || 0;
        total += end_time - start_time;
      });
    let duration = moment.duration(total || 0);
    return (
      parseInt(duration.asMinutes())?.toString()?.padStart(2, "0") +
      ":" +
      duration.seconds()?.toString()?.padStart(2, "0")
    );
  }

  function getTotalPosssession(sequences, game) {
    let total_home = 0;
    let total_away = 0;

    sequences
      .filter((s) => s.team === game?.home_team?._id)
      .forEach((s) => {
        let sorted_tackles = s?.tackles?.sort((a, b) => a.number - b.number);
        let start_time = sorted_tackles[0]?.time || 0;
        let end_time = sorted_tackles?.at(-1)?.time || 0;
        total_home += end_time - start_time;
      });

    sequences
      .filter((s) => s.team === game?.away_team?._id)
      .forEach((s) => {
        let sorted_tackles = s?.tackles?.sort((a, b) => a.number - b.number);
        let start_time = sorted_tackles[0]?.time || 0;
        let end_time = sorted_tackles?.at(-1)?.time || 0;
        total_away += end_time - start_time;
      });
    let home_percent = parseInt((total_home / (total_home + total_away)) * 100);

    return {
      home: home_percent || 0,
      away: 100 - home_percent || 0,
    };
  }

  function getSetTotals(sequences, team) {
    let total = 0;
    let complete = 0;
    sequences
      .filter((s) => s.team === team)
      .forEach((s) => {
        total += 1;
        complete += s?.status === "complete" ? 1 : 0;
      });
    return {
      total,
      complete,
      percent: parseInt((complete / total) * 100) || 0,
    };
  }

  return (
    <Main>
      <Row>
        {edit === "edit" && (
          <Team>
            <SequenceRow>
              {switched ? game?.away_team?.name : game?.home_team?.name}
            </SequenceRow>
            <SequenceColumn>
              <Sequences
                selectedSequence={tackles}
                sequences={sequence}
                team={switched ? game?.away_team?._id : game?.home_team?._id}
                onSelect={(s) => {
                  setTeam(
                    switched ? game?.away_team?._id : game?.home_team?._id
                  );
                  if (s === tackles) {
                    setTackles({
                      team: switched
                        ? game?.away_team?._id
                        : game?.home_team?._id,
                      tackles: [],
                    });
                  } else {
                    setTackles(s);
                  }
                }}
                onDelete={(seq) => {
                  let new_sequence = sequence.filter((s) => s !== seq);
                  setSequence(new_sequence);
                  updateGame({
                    id: game._id,
                    home_sets: new_sequence.filter((s) =>
                      s.team_id === switched
                        ? game?.away_team?._id
                        : game?.home_team?._id
                    ),
                  });
                }}
              />
            </SequenceColumn>
            <div>
              Sets:{" "}
              {
                getSetTotals(
                  sequence,
                  switched ? game?.away_team?._id : game?.home_team?._id
                )?.complete
              }
              /
              {
                getSetTotals(
                  sequence,
                  switched ? game?.away_team?._id : game?.home_team?._id
                )?.total
              }{" "}
              (
              {
                getSetTotals(
                  sequence,
                  switched ? game?.away_team?._id : game?.home_team?._id
                )?.percent
              }
              %)
            </div>
            <div>
              Total tackles:{" "}
              {getTotalTackles(
                sequence,
                switched ? game?.away_team?._id : game?.home_team?._id
              )}
            </div>
            <div>
              Total metres gained:{" "}
              {getTotalMetres(
                sequence,
                switched ? game?.away_team?._id : game?.home_team?._id
              )}
              m
            </div>
            <div>
              Time:{" "}
              {getTotalTime(
                sequence,
                switched ? game?.away_team?._id : game?.home_team?._id
              )}
            </div>
            <div>
              Possession:{" "}
              {
                getTotalPosssession(sequence, game)?.[
                  switched ? "away" : "home"
                ]
              }
              %
            </div>
            <Button
              onClick={() => {
                updateGame({
                  id: game._id,
                  [switched ? "away_sets" : "home_sets"]: [],
                });
                setSequence(
                  sequence.filter(
                    (s) =>
                      s.team_id ===
                      (switched ? game?.away_team?._id : game?.home_team?._id)
                  )
                );
              }}
            >
              CLEAR DATA
            </Button>
          </Team>
        )}
        <Column>
          <PitchContainer>
            <img
              src={Pitch}
              height={700}
              alt="Rugby Pitch"
              onClick={(e) => {
                var rect = e.target.getBoundingClientRect();
                var x = e.clientX - rect.left; //x position within the element.
                var y = e.clientY - rect.top; //y position within the element.
                console.log("Left? : " + x + " ; Top? : " + y + ".");

                let new_tackles = [...tackles?.tackles];
                let index = new_tackles.findIndex(
                  (t) => t.number === tackleNumber
                );

                if (index > -1) {
                  new_tackles[index] = {
                    x,
                    y,
                    type:
                      x <= left_try_line || x >= right_try_line
                        ? "try"
                        : "tackle",
                    number: new_tackles[index].number,
                    time: new_tackles[index].time || Date.now(),
                  };
                } else {
                  new_tackles.push({
                    x,
                    y,
                    type:
                      x <= left_try_line || x >= right_try_line
                        ? "try"
                        : "tackle",
                    time: Date.now(),
                    number: tackleNumber,
                  });
                }
                if (tackleNumber === -1) {
                  setTackleNumber(1);
                } else {
                  setTackleNumber(tackleNumber + 1);
                }

                setTackles({
                  ...tackles,
                  tackles: new_tackles,
                });
                let number_tackles = new_tackles?.sort(
                  (a, b) => b.number - a.number
                )?.[0]?.number;
                if (
                  !window.ENV?.REACT_APP_SKY === "true" &&
                  !process.env.REACT_APP_SKY === "true"
                ) {
                  update("Clock", [
                    {
                      name: "Metres Gained",
                      value: {
                        text: Math.abs(
                          Math.round(
                            (Math.max(
                              left_try_line,
                              Math.min(right_try_line, new_tackles?.at(-1).x)
                            ) -
                              Math.max(
                                left_try_line,
                                Math.min(right_try_line, new_tackles?.[0].x)
                              )) /
                              pixels_per_metre
                          )
                        ),
                      },
                    },
                    {
                      name: "Phase",
                      value: {
                        text: number_tackles,
                      },
                    },
                  ]);
                } else {
                  if (number_tackles > 0) {
                    updateGraphic("CLOCK", {
                      type: "PENALTY_TEXT",
                      caption: "",
                    });
                  }

                  updateGraphic("CLOCK", {
                    type: "PHASE",
                    phase: number_tackles,
                  });

                  if (!game?.competition?.variables?.disable_metres_popout) {
                    updateGraphic("CLOCK", {
                      type: "METRES",
                      metres:
                        (team === game?.home_team?._id && switched === false) ||
                        (team === game?.away_team?._id && switched === true)
                          ? Math.round(
                              (Math.max(
                                left_try_line,
                                Math.min(right_try_line, new_tackles?.[0].x)
                              ) -
                                Math.max(
                                  left_try_line,
                                  Math.min(
                                    right_try_line,
                                    new_tackles?.at(-1).x
                                  )
                                )) /
                                pixels_per_metre
                            )
                          : Math.round(
                              (Math.max(
                                left_try_line,
                                Math.min(right_try_line, new_tackles?.[0].x)
                              ) -
                                Math.max(
                                  left_try_line,
                                  Math.min(
                                    right_try_line,
                                    new_tackles?.at(-1).x
                                  )
                                )) /
                                pixels_per_metre
                            ) * -1,
                    });
                    clearTimeout(metres_timeout);
                    metres_timeout = setTimeout(() => {
                      if (
                        !window.ENV?.REACT_APP_SKY === "true" &&
                        !process.env.REACT_APP_SKY === "true"
                      ) {
                        update("Clock", [
                          {
                            name: "Metres Gained",
                            value: {
                              text: 0,
                            },
                          },
                        ]);
                      } else {
                        // updateGraphic("CLOCK", {
                        //   type: "METRES",
                        //   metres: 0,
                        // });
                      }
                    }, 2000);
                  }
                }
              }}
            />
            {tackles?.tackles?.length > 1 && (
              <svg
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  pointerEvents: "none",
                }}
              >
                <line
                  x1={Math.max(
                    left_try_line,
                    Math.min(right_try_line, tackles?.tackles?.[0].x)
                  )}
                  y1={100}
                  x2={Math.max(
                    left_try_line,
                    Math.min(right_try_line, tackles?.tackles?.at(-1).x)
                  )}
                  y2={100}
                  stroke="rgb(255,0,0)"
                  strokeWidth="4"
                />
                <text
                  x={
                    Math.max(
                      100,
                      Math.min(right_try_line, tackles?.tackles?.[0].x)
                    ) +
                    (Math.max(100, Math.min(tackles?.tackles?.at(-1).x)) -
                      Math.max(100, Math.min(tackles?.tackles?.[0].x))) /
                      2
                  }
                  y="80"
                  fill="red"
                  textAnchor="middle"
                  fontWeight="bold"
                  fontSize="20"
                >
                  {Math.abs(
                    Math.round(
                      (Math.max(
                        left_try_line,
                        Math.min(right_try_line, tackles?.tackles?.at(-1).x)
                      ) -
                        Math.max(
                          left_try_line,
                          Math.min(right_try_line, tackles?.tackles?.[0].x)
                        )) /
                        pixels_per_metre
                    )
                  )}
                  m
                </text>
              </svg>
            )}
            {tackles?.tackles
              ?.sort((a, b) => a.number - b.number)
              ?.map((tackle, index) => {
                return (
                  index > 0 && (
                    <svg
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                      }}
                    >
                      <line
                        x1={tackles?.tackles?.[index - 1].x}
                        y1={tackles?.tackles?.[index - 1].y}
                        x2={tackle.x}
                        y2={tackle.y}
                        stroke="rgb(255,0,0)"
                        strokeWidth="3"
                      />
                    </svg>
                  )
                );
              })}
            {tackles?.tackles
              ?.sort((a, b) => a.number - b.number)
              ?.map((tackle, index) => {
                return (
                  <Circle
                    style={{
                      top: tackle.y + "px",
                      left: tackle.x + "px",
                      transform: "translate(-50%, -50%)",
                      backgroundColor:
                        tackle.type === "try" ? "#FFFF00" : "#FFF",
                      fontWeight: "bold",
                    }}
                  >
                    {tackle.number === -1
                      ? "S"
                      : tackle.type === "try"
                      ? "T"
                      : tackle.number}
                  </Circle>
                );
              })}
          </PitchContainer>

          <Row style={{ width: "100%" }}>
            <Column style={{ width: "100%" }}>
              <Row
                style={{
                  width: "100%",
                  flexDirection: switched ? "row-reverse" : "row",
                }}
              >
                <Team>
                  <TeamButton
                    style={{ width: "80%" }}
                    selected={team === game?.home_team?._id}
                    onClick={() => {
                      setTeam(game?.home_team?._id);

                      if (tackleNumber > -1) {
                        updateGame({
                          id: game._id,
                          away_sets: [
                            ...sequence.filter(
                              (s) => s.team_id === game?.away_team?._id
                            ),
                            tackles,
                          ],
                        });
                        setSequence([...sequence, tackles]);
                      }
                      setTackles({ team: game?.home_team?._id, tackles: [] });
                      setTackleNumber(-1);
                    }}
                  >
                    {game?.home_team?.name}
                  </TeamButton>
                </Team>

                <Button onClick={() => setSwitched(!switched)}>
                  <HiOutlineSwitchHorizontal />
                </Button>

                <Team>
                  <TeamButton
                    style={{ width: "80%" }}
                    selected={team === game?.away_team?._id}
                    onClick={() => {
                      setTeam(game?.away_team?._id);

                      if (tackleNumber > -1) {
                        updateGame({
                          id: game._id,
                          home_sets: [
                            ...sequence.filter(
                              (s) => s.team === game?.home_team?._id
                            ),
                            tackles,
                          ],
                        });
                        setSequence([...sequence, tackles]);
                      }
                      setTackles({ team: game?.away_team?._id, tackles: [] });
                      setTackleNumber(-1);
                    }}
                  >
                    {game?.away_team?.name}
                  </TeamButton>
                </Team>
              </Row>
              {!team && <Row>Select a team</Row>}
              {team && (
                <>
                  <Row>
                    <TackleButton
                      style={{
                        width: "200px",
                        justifyContent: "center",
                        fontSize: "20px",
                        padding: "5px",
                      }}
                      onClick={() => {
                        if (
                          !window.ENV?.REACT_APP_SKY === "true" &&
                          !process.env.REACT_APP_SKY === "true"
                        ) {
                          update("Clock", [
                            {
                              name: "Metres Gained",
                              value: {
                                text: 0,
                              },
                            },
                            {
                              name: "Phase",
                              value: {
                                text: -1,
                              },
                            },
                          ]);
                        } else {
                          updateGraphic("CLOCK", {
                            type: "PHASE",
                            phase: 6,
                          });
                          if (
                            !game?.competition?.variables?.disable_metres_popout
                          ) {
                            updateGraphic("CLOCK", {
                              type: "METRES",
                              metres: 0,
                            });
                          }
                        }
                        setTackleNumber(-1);
                        setTackles({ team: game?.home_team?._id, tackles: [] });
                        clearTimeout(metres_timeout);

                        // updateGame({
                        //   id: game._id,
                        //   home_phase: 0,
                        //   metres_gained: 0,
                        // });
                      }}
                    >
                      RESET
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        setTackleNumber(-1);
                      }}
                      selected={tackleNumber === -1}
                    >
                      START
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        setTackleNumber(0);
                      }}
                      selected={tackleNumber === 0}
                    >
                      T0
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        setTackleNumber(1);
                      }}
                      selected={tackleNumber === 1}
                    >
                      T1
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        setTackleNumber(2);
                      }}
                      selected={tackleNumber === 2}
                    >
                      T2
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        setTackleNumber(3);
                      }}
                      selected={tackleNumber === 3}
                    >
                      T3
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        setTackleNumber(4);
                      }}
                      selected={tackleNumber === 4}
                    >
                      T4
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        setTackleNumber(5);
                      }}
                      selected={tackleNumber === 5}
                    >
                      T5
                    </TackleButton>
                  </Row>
                  <Row>
                    <TackleButton
                      onClick={() => {
                        if (
                          !window.ENV?.REACT_APP_SKY === "true" &&
                          !process.env.REACT_APP_SKY === "true"
                        ) {
                          update("Clock", [
                            {
                              name: "Metres Gained",
                              value: {
                                text: 0,
                              },
                            },
                            {
                              name: "Phase",
                              value: {
                                text: -1,
                              },
                            },
                          ]);
                        } else {
                          updateGraphic("CLOCK", {
                            type: "PHASE",
                            phase: 6,
                          });
                          if (
                            !game?.competition?.variables?.disable_metres_popout
                          ) {
                            updateGraphic("CLOCK", {
                              type: "METRES",
                              metres: 0,
                            });
                          }
                        }
                        setTackleNumber(-1);
                        let t = team === game?.home_team?._id ? "home" : "away";
                        updateGame({
                          id: game._id,
                          [t + "_sets"]: [
                            ...sequence.filter((s) => s.team === team),
                            { ...tackles, status: "complete" },
                          ],
                        });
                        setSequence([
                          ...sequence,
                          { ...tackles, status: "complete" },
                        ]);
                        setTackles({
                          team:
                            team === game?.home_team?._id
                              ? game?.away_team?._id
                              : game?.home_team?._id,
                          tackles: [],
                        });
                        setTeam(
                          team === game?.home_team?._id
                            ? game?.away_team?._id
                            : game?.home_team?._id
                        );
                      }}
                    >
                      Complete
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        setTackleNumber(-1);
                        let t = team === game?.home_team?._id ? "home" : "away";
                        updateGame({
                          id: game._id,
                          [t + "_sets"]: [
                            ...sequence.filter((s) => s.team === team),
                            { ...tackles, status: "end" },
                          ],
                        });
                        setSequence([
                          ...sequence,
                          { ...tackles, status: "end" },
                        ]);
                        setTackles({
                          team:
                            team === game?.home_team?._id
                              ? game?.away_team?._id
                              : game?.home_team?._id,
                          tackles: [],
                        });
                        setTeam(
                          team === game?.home_team?._id
                            ? game?.away_team?._id
                            : game?.home_team?._id
                        );
                      }}
                    >
                      End
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        let t = team === game?.home_team?._id ? "home" : "away";

                        setTackleNumber(-1);
                        updateGame({
                          id: game._id,
                          [t + "_sets"]: [
                            ...sequence.filter((s) => s.team === team),
                            { ...tackles, status: "penalty" },
                          ],
                          home_team_stats: {
                            ...game?.["home_team_stats"],
                            Penalties:
                              (parseInt(
                                game?.home_team_stats?.Penalties ?? 0
                              ) ?? 0) + 1,
                          },
                        });

                        setSequence([
                          ...sequence,
                          { ...tackles, status: "penalty" },
                        ]);
                        setTackles({
                          team:
                            team === game?.home_team?._id
                              ? game?.away_team?._id
                              : game?.home_team?._id,
                          tackles: [],
                        });
                        if (team === game?.away_team?._id) {
                          setTeam(
                            team === game?.home_team?._id
                              ? game?.away_team?._id
                              : game?.home_team?._id
                          );
                        }
                      }}
                    >
                      {game?.home_team?.short_name} Penalty
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        let t = team === game?.home_team?._id ? "home" : "away";
                        setTackleNumber(-1);
                        updateGame({
                          id: game._id,
                          [t + "_sets"]: [
                            ...sequence.filter((s) => s.team === team),
                            { ...tackles, status: "penalty" },
                          ],
                          away_team_stats: {
                            ...game?.["away_team_stats"],
                            Penalties:
                              (parseInt(
                                game?.away_team_stats?.Penalties ?? 0
                              ) ?? 0) + 1,
                          },
                        });
                        setSequence([
                          ...sequence,
                          { ...tackles, status: "penalty" },
                        ]);
                        setTackles({
                          team:
                            team === game?.home_team?._id
                              ? game?.away_team?._id
                              : game?.home_team?._id,
                          tackles: [],
                        });
                        if (team === game?.home_team?._id) {
                          setTeam(
                            team === game?.home_team?._id
                              ? game?.away_team?._id
                              : game?.home_team?._id
                          );
                        }
                      }}
                    >
                      {game?.away_team?.short_name} Penalty
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        let t = team === game?.home_team?._id ? "home" : "away";
                        setTackleNumber(-1);
                        updateGame({
                          id: game._id,
                          [t + "_sets"]: [
                            ...sequence.filter((s) => s.team === team),
                            { ...tackles, status: "turnover" },
                          ],
                        });

                        setSequence([
                          ...sequence,
                          { ...tackles, status: "turnover" },
                        ]);
                        setTackles({
                          team:
                            team === game?.home_team?._id
                              ? game?.away_team?._id
                              : game?.home_team?._id,
                          tackles: [],
                        });
                        setTeam(
                          team === game?.home_team?._id
                            ? game?.away_team?._id
                            : game?.home_team?._id
                        );
                        if (
                          !window.ENV?.REACT_APP_SKY === "true" &&
                          !process.env.REACT_APP_SKY === "true"
                        ) {
                          update("Clock", [
                            {
                              name: "Metres Gained",
                              value: {
                                text: 0,
                              },
                            },
                            {
                              name: "Phase",
                              value: {
                                text: -1,
                              },
                            },
                          ]);
                        } else {
                          updateGraphic("CLOCK", {
                            type: "PHASE",
                            phase: 6,
                          });
                          if (
                            !game?.competition?.variables?.disable_metres_popout
                          ) {
                            updateGraphic("CLOCK", {
                              type: "METRES",
                              metres: 0,
                            });
                          }
                        }
                      }}
                    >
                      Turnover
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        setTackleNumber(-1);
                        let t = team === game?.home_team?._id ? "home" : "away";
                        updateGame({
                          id: game._id,
                          [t + "_sets"]: [
                            ...sequence.filter((s) => s.team === team),
                            { ...tackles, status: "turnover" },
                          ],
                        });
                        setSequence([
                          ...sequence,
                          { ...tackles, status: "turnover" },
                        ]);
                        setTackles({
                          team,
                          tackles: [],
                        });
                        if (
                          !window.ENV?.REACT_APP_SKY === "true" &&
                          !process.env.REACT_APP_SKY === "true"
                        ) {
                          update("Clock", [
                            {
                              name: "Metres Gained",
                              value: {
                                text: 0,
                              },
                            },
                            {
                              name: "Phase",
                              value: {
                                text: -1,
                              },
                            },
                          ]);
                        } else {
                          updateGraphic("CLOCK", {
                            type: "PHASE",
                            phase: 6,
                          });
                          if (
                            !game?.competition?.variables?.disable_metres_popout
                          ) {
                            updateGraphic("CLOCK", {
                              type: "METRES",
                              metres: 0,
                            });
                          }
                          updateGraphic("CLOCK", {
                            type: "PENALTY_TEXT",
                            caption: "Six again",
                            team:
                              team === game?.home_team?._id
                                ? "1"
                                : team === game?.away_team?._id
                                ? "2"
                                : "0",
                          });
                        }
                        window.control_ws.send(
                          JSON.stringify({
                            action: "animate",
                            group: localStorage.getItem("user-id"),
                            engine: window.engines?.[0]?.key,
                            data: {
                              scene: "U3_DropDown",
                              timeline: "IN",
                              data: [
                                {
                                  name: "Text",
                                  value: {
                                    text: "SET RESTART",
                                  },
                                },
                              ],
                            },
                          })
                        );
                        setTimeout(() => {
                          window.control_ws.send(
                            JSON.stringify({
                              action: "animate",
                              group: localStorage.getItem("user-id"),
                              engine: window.engines?.[0]?.key,
                              data: {
                                scene: "U3_DropDown",
                                timeline: "OUT",
                                data: [],
                              },
                            })
                          );
                        }, 3000);
                      }}
                    >
                      Six again
                    </TackleButton>
                    <TackleButton
                      onClick={() => {
                        if (
                          !window.ENV?.REACT_APP_SKY === "true" &&
                          !process.env.REACT_APP_SKY === "true"
                        ) {
                          update("Clock", [
                            {
                              name: "Metres Gained",
                              value: {
                                text: 0,
                              },
                            },
                            {
                              name: "Phase",
                              value: {
                                text: -1,
                              },
                            },
                          ]);
                        } else {
                          updateGraphic("CLOCK", {
                            type: "PHASE",
                            phase: 6,
                          });
                          if (
                            !game?.competition?.variables?.disable_metres_popout
                          ) {
                            updateGraphic("CLOCK", {
                              type: "METRES",
                              metres: 0,
                            });
                          }
                          updateGraphic("CLOCK", {
                            type: "PENALTY_TEXT",
                            caption: "",
                          });
                        }
                      }}
                    >
                      Clear tackle
                    </TackleButton>
                  </Row>
                </>
              )}
            </Column>
          </Row>
        </Column>
        {edit === "edit" && (
          <Team>
            <SequenceRow>
              {switched ? game?.home_team?.name : game?.away_team?.name}
            </SequenceRow>
            <SequenceColumn>
              <Sequences
                selectedSequence={tackles}
                sequences={sequence}
                team={switched ? game?.home_team?._id : game?.away_team?._id}
                onSelect={(s) => {
                  setTeam(
                    switched ? game?.home_team?._id : game?.away_team?._id
                  );
                  if (s === tackles) {
                    setTackles({
                      team: switched
                        ? game?.home_team?._id
                        : game?.away_team?._id,
                      tackles: [],
                    });
                  } else {
                    setTackles(s);
                  }
                }}
                onDelete={(seq) => {
                  let new_sequence = sequence.filter((s) => s !== seq);
                  setSequence(new_sequence);
                  updateGame({
                    id: game._id,
                    away_sets: new_sequence.filter((s) =>
                      s.team === switched
                        ? game?.home_team?._id
                        : game?.away_team?._id
                    ),
                  });
                }}
              />
            </SequenceColumn>
            <div>
              Sets:{" "}
              {
                getSetTotals(
                  sequence,
                  switched ? game?.home_team?._id : game?.away_team?._id
                )?.complete
              }
              /
              {
                getSetTotals(
                  sequence,
                  switched ? game?.home_team?._id : game?.away_team?._id
                )?.total
              }{" "}
              (
              {
                getSetTotals(
                  sequence,
                  switched ? game?.home_team?._id : game?.away_team?._id
                )?.percent
              }
              %)
            </div>
            <div>
              Total tackles:{" "}
              {getTotalTackles(
                sequence,
                switched ? game?.home_team?._id : game?.away_team?._id
              )}
            </div>
            <div>
              Total metres gained:{" "}
              {getTotalMetres(
                sequence,
                switched ? game?.home_team?._id : game?.away_team?._id
              )}
              m
            </div>
            <div>
              Time:{" "}
              {getTotalTime(
                sequence,
                switched ? game?.home_team?._id : game?.away_team?._id
              )}
            </div>
            <div>
              Posssession:{" "}
              {
                getTotalPosssession(sequence, game)?.[
                  switched ? "home" : "away"
                ]
              }
              %
            </div>

            <Button
              onClick={() => {
                updateGame({
                  id: game._id,
                  [switched ? "home_sets" : "away_sets"]: [],
                });
                setSequence(
                  sequence.filter(
                    (s) =>
                      s.team ===
                      (switched ? game?.away_team?._id : game?.home_team?._id)
                  )
                );
              }}
            >
              CLEAR DATA
            </Button>
          </Team>
        )}
        {window.ENV?.REACT_APP_SKY !== "true" &&
          process.env.REACT_APP_SKY !== "true" && (
            <VideoRef
              update={update}
              animate={animate}
              animateOff={animateOff}
              game={game}
            />
          )}
        <Column>
          <Row>
            <Column style={{ justifyContent: "start" }}>
              <DropDownTeam
                update={update}
                animate={animate}
                animateOff={animateOff}
                updateGraphic={updateGraphic}
                column
                game={game}
                home={true}
                selected={selected}
                setSelected={setSelected}
              />
            </Column>
            <Column style={{ justifyContent: "start" }}>
              <DropDownTeam
                update={update}
                animate={animate}
                animateOff={animateOff}
                updateGraphic={updateGraphic}
                column
                game={game}
                away={true}
                selected={selected}
                setSelected={setSelected}
              />
            </Column>
          </Row>
          <Row>
            <DropDownTeam
              update={update}
              animate={animate}
              animateOff={animateOff}
              updateGraphic={updateGraphic}
              game={game}
              selected={selected}
              setSelected={setSelected}
            />
          </Row>
          <Row style={{ width: "100%" }}>
            <Column style={{ justifyContent: "start" }}>
              <DropDown
                update={update}
                animate={animate}
                animateOff={animateOff}
                updateGraphic={updateGraphic}
                column
                game={game}
                selected={selected}
                setSelected={setSelected}
                number={0}
              />
            </Column>
            <Column style={{ justifyContent: "start" }}>
              <DropDown
                update={update}
                animate={animate}
                animateOff={animateOff}
                updateGraphic={updateGraphic}
                column
                game={game}
                selected={selected}
                setSelected={setSelected}
                number={1}
              />
            </Column>
            <Column style={{ justifyContent: "start" }}>
              <DropDown
                update={update}
                animate={animate}
                animateOff={animateOff}
                updateGraphic={updateGraphic}
                column
                game={game}
                selected={selected}
                setSelected={setSelected}
                number={2}
              />
            </Column>
          </Row>
        </Column>
      </Row>

      {window.ENV?.REACT_APP_SKY !== "true" &&
        process.env.REACT_APP_SKY !== "true" && (
          <Row>
            <DropDown
              update={update}
              animate={animate}
              animateOff={animateOff}
              updateGraphic={updateGraphic}
            />
          </Row>
        )}
    </Main>
  );
}

function Sequences({ sequences, team, onSelect, onDelete, selectedSequence }) {
  return sequences
    ?.filter((s) => s.team === team)
    ?.map((s, index) => {
      let duration =
        s?.tackles?.length > 1
          ? moment.duration(s?.tackles?.at(-1)?.time - s?.tackles?.[0]?.time)
          : null;

      return (
        <SequenceRow
          onClick={() => onSelect(s)}
          selected={s === selectedSequence}
        >
          <Column>
            <Button
              onClick={(e) => {
                onDelete(s);
                e.stopPropagation();
                return true;
              }}
            >
              Del
            </Button>
          </Column>
          <Column>#{index + 1}</Column>
          <Column>
            {s?.tackles?.filter((s) => s.number > -1)?.length} Tackles
          </Column>
          {s?.tackles?.map((tackle, tackleIndex) => {})}
          <Column>
            {Math.abs(
              Math.round(
                (Math.max(82, Math.min(933, s?.tackles?.at(-1)?.x)) -
                  Math.max(82, Math.min(933, s?.tackles?.[0]?.x))) /
                  7
              )
            ) || 0}
            m
          </Column>
          <Column>{s?.status}</Column>

          <Column>
            {duration
              ? parseInt(duration?.asMinutes())?.toString()?.padStart(2, "0") +
                ":" +
                duration?.seconds()?.toString()?.padStart(2, "0")
              : ""}
          </Column>
        </SequenceRow>
      );
    });
}

const PENALTY_OPTIONS = [
  "Offside",
  "Obstruction",
  "Foul Play",
  "Ball Steal",
  "Flop",
  "Interference",
  "Holding Down",
  "Dissent",
  "High Tackle",
  "Dangerous Tackle",
  "Markers Not Square",
  "Incorrect Play The Ball",
  "Double Movement",
  "20m Restart",
  "20m Drop Out",
  "Knock On",
  "Forward Pass",
  "Accidental Offside",
  "In Touch",
  "Out On The Full",
  "Held Up",
  "Hand On Ball",
  "Professional Foul",
  "Handover",
  "Unnecessary Endangerment",
  "Late Contact",
  "Play The Ball",
];

const TEAM_OPTIONS = [
  { name: "{home_team} Scrum", value: "{home_team} Scrum" },
  { name: "{away_team} Scrum", value: "{away_team} Scrum" },
  {
    name: "{home_team} Goal Line Dropout",
    value: "Goal Line Dropout",
  },
  {
    name: "{away_team} Goal Line Dropout",
    value: "Goal Line Dropout",
  },
  { name: "Player injury", value: "Player injury" },
  { name: "Blood injury", value: "Blood injury" },
  { name: "{home_team} 40/20", value: "{home_team} 40/20" },
  { name: "{home_team} 20/40", value: "{home_team} 20/40" },
  { name: "{away_team} 40/20", value: "{away_team} 40/20" },
  { name: "{away_team} 20/40", value: "{away_team} 20/40" },
  {
    name: "{home_team} Video ref Try",
    value: "Video referee - On-field decision: {home_team} Try",
  },
  {
    name: "{home_team} Video ref No try",
    value: "Video referee - On-field decision: {home_team} No try",
  },
  {
    name: "{home_team} Captain's Challenge",
    value: "{home_team} Captain's Challenge",
  },
  {
    name: "{home_team} Challenge successful",
    value: "Challenge Successful",
  },
  {
    name: "{home_team} Challenge unsuccessful",
    value: "Challenge Unsuccessful",
  },
  {
    name: "{away_team} Video ref Try",
    value: "Video referee - On-field decision: {away_team} Try",
  },
  {
    name: "{away_team} Video ref No try",
    value: "Video referee - On-field decision: {away_team} No try",
  },
  {
    name: "{home_team} Penalty",
    value: "{home_team} Penalty",
  },
  {
    name: "{away_team} Penalty",
    value: "{away_team} Penalty",
  },
  {
    name: "{away_team} Captain's Challenge",
    value: "{away_team} Captain's Challenge",
  },
  {
    name: "{away_team} Challenge Successful",
    value: "Challenge Successful",
  },
  {
    name: "{away_team} Challenge Unsuccessful",
    value: "Challenge Unsuccessful",
  },
];

const DropDownMain = styled.div`
  display: flex;
  margin-right: 20px;
  margin-top: ${(props) => (props?.column ? "" : "50px")};
  margin-bottom: ${(props) => (props?.column ? "" : "50px")};
  flex-direction: ${(props) => (props?.column ? "column" : "")};
  max-height: ${(props) => (props?.column ? "600px" : "")};
`;

const BigScreenMain = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
  min-width: 223px;
`;

const PenaltyButton = styled(Button)`
  display: flex;
  justify-content: center;
  margin: 2px;
  margin-bottom: 10px;
  font-size: 11px;
  min-width: 109px;
  background-color: ${(props) => (props.selected ? "#db0a41" : "")};
  :hover {
    background-color: ${(props) => (!props.selected ? "#4c5264" : "")};
  }
`;

function DropDown({
  update,
  animate,
  animateOff,
  updateGraphic,
  column,
  game,
  selected,
  setSelected,
  number,
}) {
  return (
    <Row>
      <DropDownMain column>
        {PENALTY_OPTIONS?.sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        })
          ?.slice(
            number * (PENALTY_OPTIONS?.length / 3),
            number * (PENALTY_OPTIONS?.length / 3) + PENALTY_OPTIONS?.length / 3
          )
          .map((option) => {
            return (
              <PenaltyButton
                selected={selected === option}
                onClick={() => {
                  if (option === selected) {
                    setSelected();
                    if (
                      !window.ENV?.REACT_APP_SKY === "true" &&
                      !process.env.REACT_APP_SKY === "true"
                    ) {
                      window.control_ws.send(
                        JSON.stringify({
                          action: "animate",
                          group: localStorage.getItem("user-id"),
                          engine: window.engines?.[0]?.key,
                          data: {
                            scene: "U3_DropDown",
                            timeline: "OUT",
                            data: [],
                          },
                        })
                      );
                    } else {
                      updateGraphic("CLOCK", {
                        type: "PENALTY_TEXT",
                        caption: "",
                      });
                    }
                  } else {
                    setSelected(option);
                    if (
                      !window.ENV?.REACT_APP_SKY === "true" &&
                      !process.env.REACT_APP_SKY === "true"
                    ) {
                      window.control_ws.send(
                        JSON.stringify({
                          action: "animate",
                          group: localStorage.getItem("user-id"),
                          engine: window.engines?.[0]?.key,
                          data: {
                            scene: "U3_DropDown",
                            timeline: "IN",
                            data: [
                              {
                                name: "Text",
                                value: {
                                  text: option,
                                },
                              },
                            ],
                          },
                        })
                      );
                    } else {
                      updateGraphic("CLOCK", {
                        type: "PENALTY_TEXT",
                        caption: option,
                      });
                    }
                  }
                }}
              >
                {option}
              </PenaltyButton>
            );
          })}
      </DropDownMain>
    </Row>
  );
}

function DropDownTeam({
  update,
  animate,
  animateOff,
  updateGraphic,
  column,
  game,
  home,
  away,
  selected,
  setSelected,
}) {
  return (
    <Row>
      <DropDownMain column={column}>
        {TEAM_OPTIONS?.filter((o) => {
          return (
            (home && o?.name?.indexOf("{home_team}") > -1) ||
            (away && o?.name?.indexOf("{away_team}") > -1) ||
            (!home &&
              !away &&
              o?.name?.indexOf("{home_team}") === -1 &&
              o?.name?.indexOf("{away_team}") === -1)
          );
        })
          ?.sort((a, b) => {
            if (a?.name < b?.name) {
              return -1;
            }
            if (a?.name > b?.name) {
              return 1;
            }
            return 0;
          })
          .map((option) => {
            return (
              <PenaltyButton
                style={{ textAlign: "center" }}
                selected={selected === option}
                onClick={() => {
                  if (option === selected) {
                    setSelected();
                    if (
                      !window.ENV?.REACT_APP_SKY === "true" &&
                      !process.env.REACT_APP_SKY === "true"
                    ) {
                      window.control_ws.send(
                        JSON.stringify({
                          action: "animate",
                          group: localStorage.getItem("user-id"),
                          engine: window.engines?.[0]?.key,
                          data: {
                            scene: "U3_DropDown",
                            timeline: "OUT",
                            data: [],
                          },
                        })
                      );
                    } else {
                      updateGraphic("CLOCK", {
                        type: "PENALTY_TEXT",
                        caption: "",
                      });
                    }
                  } else {
                    setSelected(option);
                    if (
                      !window.ENV?.REACT_APP_SKY === "true" &&
                      !process.env.REACT_APP_SKY === "true"
                    ) {
                      window.control_ws.send(
                        JSON.stringify({
                          action: "animate",
                          group: localStorage.getItem("user-id"),
                          engine: window.engines?.[0]?.key,
                          data: {
                            scene: "U3_DropDown",
                            timeline: "IN",
                            data: [
                              {
                                name: "Text",
                                value: {
                                  text: option,
                                },
                              },
                            ],
                          },
                        })
                      );
                    } else {
                      updateGraphic("CLOCK", {
                        type: "PENALTY_TEXT",
                        caption: option?.value
                          ?.replace("{home_team}", game?.home_team?.short_name)
                          ?.replace("{away_team}", game?.away_team?.short_name),
                        team: home ? "1" : away ? "2" : "0",
                      });
                    }
                  }
                }}
              >
                {option?.name
                  ?.replace("{home_team}", game?.home_team?.short_name)
                  ?.replace("{away_team}", game?.away_team?.short_name)}
              </PenaltyButton>
            );
          })}
      </DropDownMain>
    </Row>
  );
}

const ClockButtons = styled.div`
  margin-top: 100px;
`;
// const VIDEO_REF_OPTIONS = ["TRY", "NO TRY", "PENALTY", "SCRUM", "TURNOVER"];
const VIDEO_REF_OPTIONS = ["TRY", "NO TRY"];

function VideoRef({ update, animate, animateOff, game }) {
  const [selected, setSelected] = useState();
  return (
    <BigScreenMain>
      <PenaltyButton
        onClick={() => {
          window.control_ws.send(
            JSON.stringify({
              action: "animate",
              group: window.engines?.[1]?.key,
              engine: window.engines?.[1]?.key,
              data: {
                scene: "BS-DECISION_v04",
                timeline: "IN",
                data: [],
              },
            })
          );
        }}
      >
        VIDEO REF CUE
      </PenaltyButton>
      <PenaltyButton
        onClick={() => {
          window.control_ws.send(
            JSON.stringify({
              action: "animate",
              group: window.engines?.[1]?.key,
              engine: window.engines?.[1]?.key,
              data: {
                scene: "BS-DECISION_v04",
                timeline: "INTRO",
                data: [],
              },
            })
          );
        }}
      >
        VIDEO REF PLAY
      </PenaltyButton>
      {VIDEO_REF_OPTIONS.map((option) => {
        return (
          <PenaltyButton
            selected={selected === option}
            onClick={() => {
              if (option === selected) {
                setSelected();
                window.control_ws.send(
                  JSON.stringify({
                    action: "animate",
                    group: window.engines?.[1]?.key,
                    engine: window.engines?.[1]?.key,
                    data: {
                      scene: "BS-DECISION_v04",
                      timeline: "OUT",
                      data: [],
                    },
                  })
                );
              } else {
                setSelected(option);
                let timeline = "";
                switch (option) {
                  case "TRY":
                    timeline = "TRY-IN";
                    break;
                  case "NO TRY":
                    timeline = "NOTRY-IN";
                    break;
                  case "PENALTY":
                    timeline = "PENALTY-IN";
                    break;
                  case "SCRUM":
                    timeline = "SCRUM-IN";
                    break;
                  case "TURNOVER":
                    timeline = "TURNOVER-IN";
                    break;
                  default:
                }
                window.control_ws.send(
                  JSON.stringify({
                    action: "animate",
                    group: window.engines?.[1]?.key,
                    engine: window.engines?.[1]?.key,
                    data: {
                      scene: "BS-DECISION_v04",
                      timeline: timeline,
                      data: [
                        {
                          name: "Text",
                          value: {
                            text: option,
                          },
                        },
                      ],
                    },
                  })
                );
              }
            }}
          >
            {option}
          </PenaltyButton>
        );
      })}
      <ClockButtons>
        <PenaltyButton
          onClick={() => {
            window.control_ws.send(
              JSON.stringify({
                action: "animate",
                group: window.engines?.[1]?.key,
                engine: window.engines?.[1]?.key,
                data: {
                  scene: "BS-Clock",
                  timeline: "IN",
                  data: [
                    {
                      name: "HomeName",
                      value: { text: game?.home_team?.short_name },
                    },
                    {
                      name: "AwayName",
                      value: { text: game?.away_team?.short_name },
                    },
                  ],
                },
              })
            );
          }}
        >
          CLOCK IN
        </PenaltyButton>
        <PenaltyButton
          onClick={() => {
            window.control_ws.send(
              JSON.stringify({
                action: "animate",
                group: window.engines?.[1]?.key,
                engine: window.engines?.[1]?.key,
                data: {
                  scene: "BS-Clock",
                  timeline: "OUT",
                  data: [],
                },
              })
            );
          }}
        >
          CLOCK OUT
        </PenaltyButton>
      </ClockButtons>

      <ClockButtons>
        <PenaltyButton
          onClick={() => {
            window.control_ws.send(
              JSON.stringify({
                action: "animate",
                group: window.engines?.[1]?.key,
                engine: window.engines?.[1]?.key,
                data: {
                  scene: "FF_OBIT",
                  timeline: "IN",
                  data: [],
                },
              })
            );
          }}
        >
          TRIBUTE IN
        </PenaltyButton>
        <PenaltyButton
          onClick={() => {
            window.control_ws.send(
              JSON.stringify({
                action: "animate",
                group: window.engines?.[1]?.key,
                engine: window.engines?.[1]?.key,
                data: {
                  scene: "FF_OBIT",
                  timeline: "OUT",
                  data: [],
                },
              })
            );
          }}
        >
          TRIBUTE OUT
        </PenaltyButton>
      </ClockButtons>

      {/* <ClockButtons>
        <PenaltyButton
          onClick={() => {
            window.control_ws.send(
              JSON.stringify({
                action: "animate",
                group: window.engines?.[1]?.key,
                engine: window.engines?.[1]?.key,
                data: {
                  scene: "BS_UkraineFlag",
                  timeline: "IN",
                  data: [],
                },
              })
            );
          }}
        >
          UKRAINE IN
        </PenaltyButton>
        <PenaltyButton
          onClick={() => {
            window.control_ws.send(
              JSON.stringify({
                action: "animate",
                group: window.engines?.[1]?.key,
                engine: window.engines?.[1]?.key,
                data: {
                  scene: "BS_UkraineFlag",
                  timeline: "OUT",
                  data: [],
                },
              })
            );
          }}
        >
          UKRAINE OUT
        </PenaltyButton>
      </ClockButtons> */}
    </BigScreenMain>
  );
}

export default Tackles;
