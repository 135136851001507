const POSITIONS = [
  "RW",
  "FB",
  "LW",
  "RC",
  "LC",
  "SO",
  "SH",
  "PR 1",
  "HK",
  "PR 2",
  "SR 1",
  "LF",
  "SR 2",
];
export class HeroFormation {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
    continueVizPage,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.sendContinue = continueVizPage;
    this.name = "HeroFormation";
    this.downloadPage = "7400";
    this.page = "45";
    this.stage = 0;
    this.data = [];
  }

  async preview({ options }) {
    try {
      this.onAir = false;

      let xml = await this.getVizPage({ page: this.downloadPage });

      this.buildHome(xml, options);
      this.buildAway(xml, options);
      this.stage = 0;
      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });
      this.sendPreview("page:read " + this.page);
    } catch (err) {
      console.error(err);
    }
  }
  animate({ options }) {
    this.onAir = true;
    if (this.stage === 0) {
      this.sendAnimate({ page: this.page });
    } else {
      this.sendContinue(this.page);
    }
    this.stage++;
  }
  animateOff({ options }) {
    this.onAir = false;
    this.stage = 0;
    this.sendAnimateOff({ page: this.page });
  }

  buildHome(xml, options) {
    let homeTeam = options.game.home_team;

    let players = options.homeLineup.sort((a, b) => a.order - b.order);

    xml.payload.field.find((item) => {
      return item.attr.name === "010"; // Home Team Badge
    }).value =
      homeTeam?.variables?.alt_badge || homeTeam?.variables?.badge || "";

    xml.payload.field.find((item) => {
      return item.attr.name === "011"; // Home Team Badge
    }).value = homeTeam?.short_name ?? homeTeam?.name;

    var manager = xml.payload.field.find((item) => {
      return item.attr.name === "100"; // Home Manager
    });
    let managerData = options.homeManager;

    // manager image
    manager.value =
      homeTeam?.variables?.player_image_path +
      "/TORSO/" +
      managerData?.player?.first_name
        ?.toUpperCase()
        ?.replaceAll("'", "")
        ?.replaceAll(" ", "_") +
      "_" +
      managerData?.player?.last_name
        ?.toUpperCase()
        ?.replaceAll("'", "")
        ?.replaceAll(" ", "_");

    xml.payload.field.find((item) => {
      return item.attr.name === "100.FirstName"; // Home Manager
    }).value = managerData?.player?.first_name_upper;

    xml.payload.field.find((item) => {
      return item.attr.name === "100.Surname"; // Home Manager
    }).value = managerData?.player?.last_name_upper;

    xml.payload.field.find((item) => {
      return item.attr.name === "100.Title"; // Home Manager
    }).value = managerData.player?.title;

    // loop through starting 11 (Players left)
    players
      ?.sort((a, b) => {
        let a_index = POSITIONS?.findIndex((i) => i === a?.squad?.position);
        a_index = a_index === -1 ? a?.order : a_index;
        let b_index = POSITIONS?.findIndex((i) => i === b?.squad?.position);
        b_index = b_index === -1 ? b?.order : b_index;
        if (a_index > b_index) {
          return 1;
        }
        if (a_index < b_index) {
          return -1;
        }

        return 0;
      })
      ?.forEach((player, index) => {
        try {
          if (index === 17) {
            xml.payload.field.find((item) => {
              return item.attr.name === "280.Surname";
            }).value = player?.squad?.player?.last_name_upper;

            xml.payload.field.find((item) => {
              return item.attr.name === "280.Number";
            }).value =
              player?.squad?.shirt_number ?? player?.squad?.opta_shirt_number;
          } else {
            var playerTabnNo = index + 11 + "0";
            var formationImageTabNo = index + 11 + "2";

            console.log(playerTabnNo, "****", formationImageTabNo);

            var playerTab = xml.payload.field.find((item) => {
              return item.attr.name === playerTabnNo;
            }); // Tab Group
            var formationTab = xml.payload.field.find((item) => {
              return item.attr.name === formationImageTabNo;
            }); // Tab Group

            // start player Tab attributes
            // Hero Image (root)
            if (formationTab) {
              formationTab.value =
                homeTeam?.variables?.player_image_path +
                "/" +
                player?.squad?.player?.first_name
                  ?.toUpperCase()
                  ?.replaceAll("'", "")
                  ?.replaceAll(" ", "_") +
                "_" +
                player?.squad?.player?.last_name
                  ?.toUpperCase()
                  ?.replaceAll("'", "")
                  ?.replaceAll(" ", "_");
            }
            try {
              xml.payload.field.find((item) => {
                return item.attr.name === playerTabnNo;
              }).value =
                homeTeam?.variables?.player_image_path +
                "/TORSO/" +
                player?.squad?.player?.first_name
                  ?.toUpperCase()
                  ?.replaceAll("'", "")
                  ?.replaceAll(" ", "_") +
                "_" +
                player?.squad?.player?.last_name
                  ?.toUpperCase()
                  ?.replaceAll("'", "")
                  ?.replaceAll(" ", "_");
            } catch (err) {}

            xml.payload.field.find((item) => {
              return item.attr.name === playerTabnNo + ".FirstName";
            }).value = player?.squad?.player?.first_name_upper;

            xml.payload.field.find((item) => {
              return item.attr.name === playerTabnNo + ".Surname";
            }).value = player?.squad?.player?.last_name_upper;

            xml.payload.field.find((item) => {
              return item.attr.name === playerTabnNo + ".Number";
            }).value =
              player?.squad?.shirt_number ?? player?.squad?.opta_shirt_number;
          }
        } catch (err) {
          debugger;
        }
      });
  }

  buildAway(xml, options) {
    let awayTeam = options.game.away_team;

    let players = options.awayLineup.sort((a, b) => a.order - b.order);

    xml.payload.field.find((item) => {
      return item.attr.name === "020"; // Away Team Badge
    }).value =
      awayTeam?.variables?.alt_badge || awayTeam?.variables?.badge || "";

    xml.payload.field.find((item) => {
      return item.attr.name === "021"; // Home Team Badge
    }).value = awayTeam?.short_name ?? awayTeam?.name;

    let managerData = options.awayManager;

    var manager = xml.payload.field.find((item) => {
      return item.attr.name === "300"; // Away Manager
    });

    // // manager image
    manager.value =
      awayTeam?.variables?.player_image_path +
      "/TORSO/" +
      managerData?.player?.first_name
        ?.toUpperCase()
        ?.replaceAll("'", "")
        ?.replaceAll(" ", "_") +
      "_" +
      managerData?.player?.last_name
        ?.toUpperCase()
        ?.replaceAll("'", "")
        ?.replaceAll(" ", "_");
    xml.payload.field.find((item) => {
      return item.attr.name === "300.FirstName"; // Away Manager
    }).value = managerData?.player?.first_name_upper;

    xml.payload.field.find((item) => {
      return item.attr.name === "300.Surname"; // Away Manager
    }).value = managerData?.player?.last_name_upper;

    xml.payload.field.find((item) => {
      return item.attr.name === "300.Title"; // Away Manager
    }).value = managerData?.player?.title;

    // loop through starting 11 (Players left)
    players
      ?.sort((a, b) => {
        let a_index = POSITIONS?.findIndex((i) => i === a?.squad?.position);
        a_index = a_index === -1 ? a?.order : a_index;
        let b_index = POSITIONS?.findIndex((i) => i === b?.squad?.position);
        b_index = b_index === -1 ? b?.order : b_index;
        if (a_index > b_index) {
          return 1;
        }
        if (a_index < b_index) {
          return -1;
        }

        return 0;
      })
      ?.forEach((player, index) => {
        try {
          if (index === 17) {
            xml.payload.field.find((item) => {
              return item.attr.name === "480.Surname";
            }).value = player?.squad?.player?.last_name_upper;

            xml.payload.field.find((item) => {
              return item.attr.name === "480.Number";
            }).value =
              player?.squad?.shirt_number ?? player?.squad?.opta_shirt_number;
          } else {
            var playerTabnNo = index + 31 + "0";
            var formationImageTabNo = index + 31 + "2";

            var formationTab = xml.payload.field.find((item) => {
              return item.attr.name === formationImageTabNo;
            }); // Tab Group

            // start player Tab attributes
            // Hero Image (root)
            if (formationTab) {
              formationTab.value =
                awayTeam?.variables?.player_image_path +
                "/" +
                player?.squad?.player?.first_name
                  ?.toUpperCase()
                  ?.replaceAll("'", "")
                  ?.replaceAll(" ", "_") +
                "_" +
                player?.squad?.player?.last_name
                  ?.toUpperCase()
                  ?.replaceAll("'", "")
                  ?.replaceAll(" ", "_");
            }
            try {
              xml.payload.field.find((item) => {
                return item.attr.name === playerTabnNo;
              }).value =
                awayTeam?.variables?.player_image_path +
                "/TORSO/" +
                player?.squad?.player?.first_name
                  ?.toUpperCase()
                  ?.replaceAll("'", "")
                  ?.replaceAll(" ", "_") +
                "_" +
                player?.squad?.player?.last_name
                  ?.toUpperCase()
                  ?.replaceAll("'", "")
                  ?.replaceAll(" ", "_");
            } catch (err) {}

            xml.payload.field.find((item) => {
              return item.attr.name === playerTabnNo + ".FirstName";
            }).value = player?.squad?.player?.first_name_upper;

            xml.payload.field.find((item) => {
              return item.attr.name === playerTabnNo + ".Surname";
            }).value = player?.squad?.player?.last_name_upper;

            xml.payload.field.find((item) => {
              return item.attr.name === playerTabnNo + ".Number";
            }).value =
              player?.squad?.shirt_number ?? player?.squad?.opta_shirt_number;
          }
        } catch (err) {}
      });
  }
}
