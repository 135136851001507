import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import {
  GiAmericanFootballBall,
  GiRugbyConversion,
  GiSoccerBall,
} from "react-icons/gi";
import { IoIosGitCompare, IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import moment from "moment";
import { ScrollBar } from "../../Theme/Hyper";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
import { UIContext } from "../../contexts/UIContext";
import { ClockContext } from "../../contexts/ClockContext";

const Main = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
`;
const Team = styled(ScrollBar)`
  overflow-y: auto;
  height: 80vh;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  min-height: ${(props) => (!props.noHover ? "39px" : "")};
  padding-right: 0.5em;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  opacity: ${(props) => (props.sub ? "0.5" : "1")};
  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;
const ShirtNumber = styled.div`
  display: flex;
  justify-content: center;
  min-width: 40px;
  /* border-right: 1px solid #d6d6d6; */
  padding-left: 0.5em;
`;

const Name = styled.div`
  /* min-width: 300px; */
  display: flex;
  padding: 0.5em;
  width: 100%;
  align-items: end;
  color: ${(props) =>
    props.subtle
      ? "#fff"
      : props.fade
      ? "#181d25"
      : props.selected
      ? "#fff"
      : "#fff"};

  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  :hover {
    color: ${(props) =>
      !props.noHover && props.fade
        ? "#181d25"
        : !props.noHover && props.selected
        ? "#fff"
        : !props.noHover
        ? "#d11e42"
        : ""};
  }
`;
const FirstName = styled.div`
  display: flex;
  /* min-width: 200px; */
  /* border-right: 1px solid #d6d6d6; */
  padding-left: 0.5em;
`;
const LastName = styled.div`
  font-weight: 500;
  display: flex;
  /* min-width: 200px; */
  /* border-right: 1px solid #d6d6d6; */
  padding-left: ${(props) => (props.padLeft ? "0.5em" : "")};
`;
const Position = styled.div`
  display: flex;
  min-width: 150px;

  padding-left: 0.5em;

  font-size: 0.7em;
`;
const Sub = styled.div`
  display: flex;
  justify-content: center;
  min-width: 27px;
`;
const Captain = styled.div`
  display: flex;
  justify-content: center;
  min-width: 27px;
`;
const Checkbox = styled.input`
  width: 20px;
  height: 20px;
`;
const Right = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-evenly;
`;
const YellowCard = styled.div`
  width: 18px;
  height: 25px;
  background-color: #fffe00;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  color: #000;
  text-align: center;
`;
const RedCard = styled.div`
  width: 18px;
  height: 25px;
  background-color: #ff0034;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;

const Cards = styled.div`
  /* margin-left: auto; */
  display: flex;
`;

const Card = styled.div`
  margin-right: 10px;
  cursor: pointer;
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  opacity: ${(props) => (props.selected ? "1" : "0.1")};
  :hover {
    opacity: 1;
  }
`;

const Goals = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 10px;
`;
const Goal = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  color: ${(props) => (props.red ? "red" : "white")};
  border-radius: 2px;
  padding: 2px;
  box-sizing: border-box;
  height: 21px;
  white-space: nowrap;
  :hover {
    background-color: #d11e42;
  }
`;
const SubIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  color: ${(props) => (props.on ? "#0adb93" : props.off ? "#db0a41" : "white")};
  border-radius: 2px;
  padding: 2px;
  box-sizing: border-box;
  height: 21px;
  opacity: ${(props) => (props.on ? "1" : props.off ? "1" : " 0.2")};
  min-width: 30px;
  justify-content: flex-end;
  :hover {
    /* background-color: #d11e42; */

    opacity: 1;
  }
`;
const FootballIcon = styled(GiAmericanFootballBall)`
  margin-right: 2px;
`;
const ConversionIcon = styled(GiRugbyConversion)`
  margin-right: 2px;
`;
const BeenSubbed = styled.div`
  display: flex;
  justify-content: center;
  min-width: 20px;
  flex-direction: column;
`;
const Off = styled(IoIosArrowDown)`
  color: #db0a41;
`;
const On = styled(IoIosArrowUp)`
  color: #0adb93;
`;
const Button = styled.div`
  display: flex;
  align-items: center;
  background-color: #202731;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0.5em;
  margin-bottom: 1em;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  padding: 0.5em;
  :hover {
    background-color: #db0a41;
  }
`;
const Popup = styled.div`
  background-color: ${(props) => props.theme.panelColour};
  border-radius: 4px;
  width: 200px;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
  overflow: hidden;
`;

const PopupItem = styled.div`
  padding: 0.5em;
  :hover {
    background-color: #d11e42;
  }
  /* border-bottom: 1px solid grey; */
`;
const PopupGroup = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
`;

const HIA = styled.span`
  font-size: 0.8em;
  color: #ff730b;
`;
const HIAButton = styled(Button)`
  background-color: rgb(14 18 25);
  width: 135px;
  width: 135px;
  margin: 0;
  height: 28px;
  margin-right: 10px;
  overflow: hidden;
  text-wrap: nowrap;
  color: white;
  :hover {
    color: white;
  }
`;

const SBButton = styled(Button)`
  background-color: ${(props) =>
    props.red ? "rgb(219, 10, 65)" : props.green ? "rgb(10, 219, 147)" : ""};
  width: 45px;
  max-width: 45px;
  margin: 0;
  height: 28px;
  margin-left: 10px;
  overflow: hidden;
  text-wrap: nowrap;
  color: ${(props) => (props.red ? "white" : "black")};
  :hover {
    background-color: ${(props) =>
      props.red ? "rgb(219, 10, 65)" : props.green ? "rgb(10, 219, 147)" : ""};
  }
`;

const SBRow = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  min-width: 180px;
  justify-content: right;
  color: white;
  :hover {
    color: white;
  }
`;

export default function LineupSky({
  team,
  players,
  lineup,
  scorers,
  selectPlayer,
  selectedPlayer,
  game,
  updateGoal,
  removeGoal,
  setTeamManager,
  selecting_goal,
  failedHIA,
  passedHIA,
}) {
  const { previewGraphic, animateGraphic, animateOffGraphic } =
    useContext(GraphicsContext);
  const { update, sky, countries, seasonStats } = useContext(DataContext);
  const { setRightBarView } = useContext(UIContext);
  const { updateGame, getSquad } = useContext(APIContext);
  const { clockType, kick_off_time } = useContext(ClockContext);
  const [manager, setManager] = useState();
  const [contextMenuItem, setContextMenuItem] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  function handleContextMenu(e, player) {
    setContextMenuItem({
      item: player,
      mouseX: e.clientX,
      mouseY: e.clientY,
      showContext: true,
    });
    e.preventDefault();
    window.addEventListener("click", handleClick);
  }

  function handleClick() {
    setContextMenuItem({
      ...contextMenuItem,
      showContext: false,
    });
    window.removeEventListener("click", handleClick);
  }

  useEffect(() => {
    getSquad({ teamId: team?._id, seasonId: game.season?._id }).then((data) => {
      setManager(data.find((s) => s.type === "coach"));
      setTeamManager(data.find((s) => s.type === "coach"));
    });
  }, [team]);

  let team_type = team._id === game.home_team._id ? "home" : "away";
  let number_payers =
    game?.competition?.variables?.number_of_players_on_field ??
    (game?.competition?.competition_type === "union" ? 15 : 13);
  let use_18th_player_rule = game?.competition?.variables?.use_18th_player;
  let rugby_type = game?.competition?.competition_type;

  return (
    <Main>
      <Team>
        {new Array(lineup?.length < 18 ? 18 : lineup?.length)
          .fill(1)
          .map((item, index) => {
            let l = lineup?.find((l) => l.order === index + 1);

            let player = {
              _id: l?.squad?._id,
              ...l?.squad?.player,
              order: l?.order,
              shirt_number:
                l?.squad?.shirt_number || l?.squad?.opta_shirt_number,
              squad: JSON.parse(JSON.stringify(l?.squad ?? [])),
              yellow_card: l?.yellow_card,
              dbl_yellow_card: l?.dbl_yellow_card,
              red_card: l?.red_card,
              sub_order: l?.sub_order,
              subbed: l?.subbed,
              captain: l?.captain,
              stories: l?.squad?.player?.stories,
              sinbins: l?.sinbins,
              hia: l?.hia,
            };

            return (
              <>
                {index === number_payers && (
                  <Row fade noHover sub>
                    <ShirtNumber></ShirtNumber>
                    <Name subtle fade noHover>
                      <FirstName>Interchanges</FirstName>
                    </Name>
                  </Row>
                )}
                {use_18th_player_rule && index === 17 && (
                  <Row fade noHover sub>
                    <ShirtNumber></ShirtNumber>
                    <Name subtle fade noHover>
                      <FirstName>18th Player</FirstName>
                    </Name>
                  </Row>
                )}
                <Row
                  onDrop={() => {
                    let newHomeScorers = [...game.home_scorers];
                    let newAwayScorers = [...game.away_scorers];
                    let index = newHomeScorers.findIndex((g) => {
                      return g._id === window.goalDrag._id;
                    });
                    if (index > -1) {
                      newHomeScorers[index] = {
                        ...newHomeScorers[index],
                        player: player.squad._id,
                        own_goal: team_type !== "home",
                      };
                    } else if (index === -1) {
                      index = newAwayScorers.findIndex((g) => {
                        return g._id === window.goalDrag._id;
                      });
                      newAwayScorers[index] = {
                        ...newAwayScorers[index],
                        player: player.squad._id,
                        own_goal: team_type !== "away",
                      };
                    }

                    updateGame({
                      id: game._id,
                      home_scorers: newHomeScorers,
                      away_scorers: newAwayScorers,
                    });
                  }}
                  onDragOver={(ev) => {
                    ev.preventDefault();
                  }}
                  clickable
                  sub={!player?.squad || index >= number_payers}
                >
                  <ShirtNumber>{player?.shirt_number}</ShirtNumber>

                  <BeenSubbed>
                    {player?.subbed && player?.order <= number_payers && <On />}
                    {player?.subbed && player?.order > number_payers && <Off />}
                    {player.hia && player.order > number_payers && (
                      <HIA>HIA</HIA>
                    )}
                  </BeenSubbed>
                  <Name
                    selected={
                      selectedPlayer?._id && selectedPlayer?._id === player._id
                    }
                    onContextMenu={(e) => {
                      handleContextMenu(e, player);
                    }}
                    onClick={() => {
                      if (
                        selecting_goal ||
                        !selectedPlayer ||
                        selectedPlayer._id !== player._id
                      ) {
                        selectPlayer(player);
                      } else {
                        selectPlayer(null);
                      }
                    }}
                  >
                    <FirstName>{player?.first_name}</FirstName>
                    <LastName padLeft={player?.first_name}>
                      {player?.last_name} {player && player.captain && "(C)"}
                      <span
                        style={{
                          fontSize: ".7em",
                          fontWeight: "100",
                          alignSelf: "flex-end",
                          marginLeft: ".5em",
                        }}
                      >
                        {player?.nationality?.code ||
                          player?.nationality?.opta_code}
                      </span>
                    </LastName>
                    <Position>
                      {player?.squad?.position || player?.squad?.opta_position
                        ? "(" +
                          (player?.squad?.position?.toUpperCase() ||
                            player?.squad?.opta_position?.toUpperCase()) +
                          ")"
                        : ""}
                    </Position>
                  </Name>

                  <Goals>
                    {scorers
                      ?.filter((g) => {
                        return (
                          g.player &&
                          player.squad &&
                          g?.player === player?.squad?._id &&
                          (g?.type === "try" || g?.type === "penalty_try")
                        );
                      })
                      ?.map((goal) => {
                        return (
                          <Goal
                            draggable="true"
                            onDragStart={() => {
                              window.goalDrag = { ...goal, team: team_type };
                            }}
                            red={goal?.own_goal}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onContextMenu={(e) => {
                              e.preventDefault();
                            }}
                            onMouseDown={(e) => {
                              switch (e.button) {
                                case 0:
                                  previewGraphic("PlayerScored", {
                                    goal: goal,
                                    player: player,
                                    time: goal?.game_time_minutes,
                                    seconds: goal?.game_time_seconds,
                                    team: team,
                                    game,
                                    type: goal.type,
                                  });
                                  break;
                                case 2:
                                  removeGoal(goal);
                                  break;
                                default:
                              }
                            }}
                          >
                            <FootballIcon />
                            {goal.type === "penalty_try" && "P"}{" "}
                            {goal.period === 1
                              ? Math.min(40, goal?.game_time_minutes + 1)
                              : goal.period === 2
                              ? Math.min(80, goal?.game_time_minutes + 1)
                              : goal?.game_time_minutes + 1}
                            {"' "}
                          </Goal>
                        );
                      })}
                    {scorers?.filter((g) => {
                      return (
                        g.player &&
                        player.squad &&
                        g?.player === player?.squad?._id &&
                        g?.type === "conversion"
                      );
                    })?.length > 0 && (
                      <Goal
                        draggable="true"
                        onDragStart={() => {
                          window.goalDrag = {
                            ...scorers
                              ?.filter((g) => {
                                return (
                                  g.player &&
                                  player.squad &&
                                  g?.player === player?.squad?._id &&
                                  g?.type === "conversion"
                                );
                              })
                              ?.at(-1),
                            team: team_type,
                          };
                        }}
                        red={false}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          switch (e.button) {
                            case 0:
                              let made =
                                scorers?.filter((g) => {
                                  return (
                                    g.player &&
                                    player.squad &&
                                    g?.player === player?.squad?._id &&
                                    (g?.type === "conversion" ||
                                      g?.type === "penalty_kick")
                                  );
                                })?.length || 0;
                              let missed =
                                scorers?.filter((g) => {
                                  return (
                                    g.player &&
                                    player.squad &&
                                    g?.player === player?.squad?._id &&
                                    (g?.type === "missed_conversion" ||
                                      g?.type === "missed_penalty_kick")
                                  );
                                })?.length || 0;

                              previewGraphic("PlayerStat", {
                                player: player,
                                subtext:
                                  "goals  -  " +
                                  made +
                                  "/" +
                                  (made + missed) +
                                  "   (" +
                                  Math.floor((made / (made + missed)) * 100) +
                                  "%)",
                                team: team,
                                game,
                                category: "goals",
                                value:
                                  made +
                                  "/" +
                                  (made + missed) +
                                  "   (" +
                                  Math.floor((made / (made + missed)) * 100) +
                                  "%)",
                                type: "LT",
                              });

                              break;
                            case 2:
                              removeGoal(
                                scorers
                                  ?.filter((g) => {
                                    return (
                                      g.player &&
                                      player.squad &&
                                      g?.player === player?.squad?._id &&
                                      g?.type === "conversion"
                                    );
                                  })
                                  ?.at(-1)
                              );
                              break;
                            default:
                          }
                        }}
                      >
                        <ConversionIcon />
                        {
                          scorers?.filter((g) => {
                            return (
                              g.player &&
                              player.squad &&
                              g?.player === player?.squad?._id &&
                              g?.type === "conversion"
                            );
                          })?.length
                        }
                      </Goal>
                    )}
                    {scorers?.filter((g) => {
                      return (
                        g.player &&
                        player.squad &&
                        g?.player === player?.squad?._id &&
                        g?.type === "missed_conversion"
                      );
                    })?.length > 0 && (
                      <Goal
                        draggable="true"
                        onDragStart={() => {
                          window.goalDrag = {
                            ...scorers
                              ?.filter((g) => {
                                return (
                                  g.player &&
                                  player.squad &&
                                  g?.player === player?.squad?._id &&
                                  g?.type === "missed_conversion"
                                );
                              })
                              ?.at(-1),
                            team: team_type,
                          };
                        }}
                        red={true}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          switch (e.button) {
                            case 0:
                              let made =
                                scorers?.filter((g) => {
                                  return (
                                    g.player &&
                                    player.squad &&
                                    g?.player === player?.squad?._id &&
                                    (g?.type === "conversion" ||
                                      g?.type === "penalty_kick")
                                  );
                                })?.length || 0;
                              let missed =
                                scorers?.filter((g) => {
                                  return (
                                    g.player &&
                                    player.squad &&
                                    g?.player === player?.squad?._id &&
                                    (g?.type === "missed_conversion" ||
                                      g?.type === "missed_penalty_kick")
                                  );
                                })?.length || 0;

                              previewGraphic("PlayerStat", {
                                player: player,
                                subtext:
                                  "goals  -  " +
                                  made +
                                  "/" +
                                  (made + missed) +
                                  "   (" +
                                  Math.floor((made / (made + missed)) * 100) +
                                  "%)",
                                team: team,
                                game,
                                category: "goals",
                                value:
                                  made +
                                  "/" +
                                  (made + missed) +
                                  "   (" +
                                  Math.floor((made / (made + missed)) * 100) +
                                  "%)",
                                type: "LT",
                                //'conversion',
                                //value,
                              });

                              break;
                            case 2:
                              removeGoal(
                                scorers
                                  ?.filter((g) => {
                                    return (
                                      g.player &&
                                      player.squad &&
                                      g?.player === player?.squad?._id &&
                                      g?.type === "missed_conversion"
                                    );
                                  })
                                  ?.at(-1)
                              );
                              break;
                            default:
                          }
                        }}
                      >
                        <ConversionIcon />
                        {
                          scorers?.filter((g) => {
                            return (
                              g.player &&
                              player.squad &&
                              g?.player === player?.squad?._id &&
                              g?.type === "missed_conversion"
                            );
                          })?.length
                        }
                      </Goal>
                    )}

                    {scorers?.filter((g) => {
                      return (
                        g.player &&
                        player.squad &&
                        g?.player === player?.squad?._id &&
                        g?.type === "penalty_kick"
                      );
                    })?.length > 0 && (
                      <Goal
                        draggable="true"
                        onDragStart={() => {
                          window.goalDrag = {
                            ...scorers
                              ?.filter((g) => {
                                return (
                                  g.player &&
                                  player.squad &&
                                  g?.player === player?.squad?._id &&
                                  g?.type === "penalty_kick"
                                );
                              })
                              ?.at(-1),
                            team: team_type,
                          };
                        }}
                        red={false}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          switch (e.button) {
                            case 0:
                              let made =
                                scorers?.filter((g) => {
                                  return (
                                    g.player &&
                                    player.squad &&
                                    g?.player === player?.squad?._id &&
                                    (g?.type === "conversion" ||
                                      g?.type === "penalty_kick")
                                  );
                                })?.length || 0;
                              let missed =
                                scorers?.filter((g) => {
                                  return (
                                    g.player &&
                                    player.squad &&
                                    g?.player === player?.squad?._id &&
                                    (g?.type === "missed_conversion" ||
                                      g?.type === "missed_penalty_kick")
                                  );
                                })?.length || 0;

                              previewGraphic("PlayerStat", {
                                player: player,
                                subtext:
                                  "goals  -  " +
                                  made +
                                  "/" +
                                  (made + missed) +
                                  "   (" +
                                  Math.floor((made / (made + missed)) * 100) +
                                  "%)",
                                team: team,
                                game,
                                category: "goals",
                                value:
                                  made +
                                  "/" +
                                  (made + missed) +
                                  "   (" +
                                  Math.floor((made / (made + missed)) * 100) +
                                  "%)",
                                type: "LT",
                                //'conversion',
                                //value,
                              });

                              break;
                            case 2:
                              removeGoal(
                                scorers
                                  ?.filter((g) => {
                                    return (
                                      g.player &&
                                      player.squad &&
                                      g?.player === player?.squad?._id &&
                                      g?.type === "penalty_kick"
                                    );
                                  })
                                  ?.at(-1)
                              );
                              break;
                            default:
                          }
                        }}
                      >
                        PK{" "}
                        {
                          scorers?.filter((g) => {
                            return (
                              g.player &&
                              player.squad &&
                              g?.player === player?.squad?._id &&
                              g?.type === "penalty_kick"
                            );
                          })?.length
                        }
                      </Goal>
                    )}

                    {scorers?.filter((g) => {
                      return (
                        g.player &&
                        player.squad &&
                        g?.player === player?.squad?._id &&
                        g?.type === "missed_penalty_kick"
                      );
                    })?.length > 0 && (
                      <Goal
                        draggable="true"
                        onDragStart={() => {
                          window.goalDrag = {
                            ...scorers
                              ?.filter((g) => {
                                return (
                                  g.player &&
                                  player.squad &&
                                  g?.player === player?.squad?._id &&
                                  g?.type === "missed_penalty_kick"
                                );
                              })
                              ?.at(-1),
                            team: team_type,
                          };
                        }}
                        red={true}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          switch (e.button) {
                            case 0:
                              let made =
                                scorers?.filter((g) => {
                                  return (
                                    g.player &&
                                    player.squad &&
                                    g?.player === player?.squad?._id &&
                                    (g?.type === "conversion" ||
                                      g?.type === "penalty_kick")
                                  );
                                })?.length || 0;
                              let missed =
                                scorers?.filter((g) => {
                                  return (
                                    g.player &&
                                    player.squad &&
                                    g?.player === player?.squad?._id &&
                                    (g?.type === "missed_conversion" ||
                                      g?.type === "missed_penalty_kick")
                                  );
                                })?.length || 0;

                              previewGraphic("PlayerStat", {
                                player: player,
                                subtext:
                                  "goals  -  " +
                                  made +
                                  "/" +
                                  (made + missed) +
                                  "   (" +
                                  Math.floor((made / (made + missed)) * 100) +
                                  "%)",
                                team: team,
                                game,
                                category: "goals",
                                value:
                                  made +
                                  "/" +
                                  (made + missed) +
                                  "   (" +
                                  Math.floor((made / (made + missed)) * 100) +
                                  "%)",
                                type: "LT",
                                //'conversion',
                                //value,
                              });

                              break;
                            case 2:
                              removeGoal(
                                scorers
                                  ?.filter((g) => {
                                    return (
                                      g.player &&
                                      player.squad &&
                                      g?.player === player?.squad?._id &&
                                      g?.type === "missed_penalty_kick"
                                    );
                                  })
                                  ?.at(-1)
                              );
                              break;
                            default:
                          }
                        }}
                      >
                        PK{" "}
                        {
                          scorers?.filter((g) => {
                            return (
                              g.player &&
                              player.squad &&
                              g?.player === player?.squad?._id &&
                              g?.type === "missed_penalty_kick"
                            );
                          })?.length
                        }
                      </Goal>
                    )}

                    {scorers?.filter((g) => {
                      return (
                        g.player &&
                        player.squad &&
                        g?.player === player?.squad?._id &&
                        g?.type === "drop_goal"
                      );
                    })?.length > 0 && (
                      <Goal
                        draggable="true"
                        onDragStart={() => {
                          window.goalDrag = {
                            ...scorers
                              ?.filter((g) => {
                                return (
                                  g.player &&
                                  player.squad &&
                                  g?.player === player?.squad?._id &&
                                  g?.type === "drop_goal"
                                );
                              })
                              ?.at(-1),
                            team: team_type,
                          };
                        }}
                        red={false}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          switch (e.button) {
                            case 0:
                              break;
                            case 2:
                              removeGoal(
                                scorers
                                  ?.filter((g) => {
                                    return (
                                      g.player &&
                                      player.squad &&
                                      g?.player === player?.squad?._id &&
                                      g?.type === "drop_goal"
                                    );
                                  })
                                  ?.at(-1)
                              );
                              break;
                            default:
                          }
                        }}
                      >
                        DG{" "}
                        {
                          scorers?.filter((g) => {
                            return (
                              g.player &&
                              player.squad &&
                              g?.player === player?.squad?._id &&
                              g?.type === "drop_goal"
                            );
                          })?.length
                        }
                      </Goal>
                    )}
                  </Goals>
                  {player.hia && player.order > number_payers && (
                    <HIAButton
                      onClick={() => {
                        passedHIA({ squad_id: player?.squad?._id });
                      }}
                    >
                      Passed HIA
                    </HIAButton>
                  )}
                  {player.hia && player.order > number_payers && (
                    <HIAButton
                      onClick={() => {
                        failedHIA({ squad_id: player?.squad?._id });
                      }}
                    >
                      Failed HIA
                    </HIAButton>
                  )}
                  {window.ENV?.REACT_APP_SKY !== "true" &&
                    process.env.REACT_APP_SKY !== "true" &&
                    player?.sinbins?.length > 0 && (
                      <SinBin
                        options={{ player, clockType, team, game }}
                        animateGraphic={animateGraphic}
                        animateOffGraphic={animateOffGraphic}
                      />
                    )}
                  {player?.squad && (
                    <Cards>
                      {player.yellow_card}
                      <Card
                        selected={player && player.yellow_card}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();

                          let newLineup = [...lineup];

                          newLineup = newLineup.map((item) => {
                            return {
                              ...item,
                              squad: item.squad._id,
                            };
                          });

                          let period =
                            game.live_data &&
                            game.live_data?.periods &&
                            game.live_data?.periods.sort(
                              (a, b) => b.number - a.number
                            )?.[0];

                          newLineup.find(
                            (l) => l.squad === player.squad._id
                          ).sinbins = [
                            ...(newLineup.find(
                              (l) => l.squad === player.squad._id
                            )?.sinbins || []),
                          ];
                          switch (e.button) {
                            case 0:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).yellow_card = true;

                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).yellow_card_period = period?.number;

                              newLineup
                                .find((l) => l.squad === player.squad._id)
                                .sinbins.push({
                                  _id: Date.now(),
                                  game_time_minutes:
                                    clockType === "manual"
                                      ? parseInt(
                                          document.getElementById(
                                            "manual_minutes"
                                          ).innerText
                                        )
                                      : parseInt(
                                          document.getElementById(
                                            "stadium_minutes"
                                          ).innerText
                                        ),
                                  game_time_seconds:
                                    clockType === "manual"
                                      ? parseInt(
                                          document.getElementById(
                                            "manual_seconds"
                                          ).innerText
                                        )
                                      : parseInt(
                                          document.getElementById(
                                            "stadium_seconds"
                                          ).innerText
                                        ),
                                  period: period?.number,
                                });
                              break;
                            case 2:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).yellow_card = false;

                              break;
                            default:
                          }

                          if (game.home_team._id === team._id) {
                            newLineup = {
                              home: newLineup,
                              away: game.lineup.away,
                            };
                          } else if (game.away_team._id === team._id) {
                            newLineup = {
                              away: newLineup,
                              home: game.lineup.home,
                            };
                          }

                          if (
                            (window.ENV?.REACT_APP_SKY === "true" ||
                              process.env.REACT_APP_SKY === "true") &&
                            e.button === 0
                          ) {
                            previewGraphic("LTSINBIN", {
                              player,
                              cardType: 1,
                              team: team,
                              game,
                              show_number:
                                window.ENV?.REACT_APP_SKY !== "true" &&
                                process.env.REACT_APP_SKY !== "true"
                                  ? false
                                  : true,
                            });
                          } else {
                            previewGraphic("Card", {
                              player,
                              cardType: 1,
                              team: team,
                              game,
                            });
                          }
                          updateGame({
                            id: game._id,
                            lineup: newLineup,
                          });
                        }}
                      >
                        <YellowCard>
                          {player?.sinbins?.length > 0
                            ? player?.sinbins?.length
                            : ""}
                        </YellowCard>
                      </Card>
                      <Card
                        selected={player && player.red_card}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();

                          let newLineup = [...lineup];

                          newLineup = newLineup.map((item) => {
                            return {
                              ...item,
                              squad: item.squad._id,
                            };
                          });

                          let period =
                            game.live_data &&
                            game.live_data?.periods &&
                            game.live_data?.periods.sort(
                              (a, b) => b.number - a.number
                            )[0];

                          switch (e.button) {
                            case 0:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).red_card = true;
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).red_card_time =
                                clockType === "manual"
                                  ? parseInt(
                                      document.getElementById("manual_minutes")
                                        .innerText
                                    )
                                  : parseInt(
                                      document.getElementById("stadium_minutes")
                                        .innerText
                                    );
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).red_card_period = period?.number;
                              break;
                            case 2:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).red_card = false;

                              break;
                            default:
                          }

                          if (game.home_team._id === team._id) {
                            newLineup = {
                              home: newLineup,
                              away: game.lineup.away,
                            };
                          } else if (game.away_team._id === team._id) {
                            newLineup = {
                              away: newLineup,
                              home: game.lineup.home,
                            };
                          }
                          if (
                            (window.ENV?.REACT_APP_SKY === "true" ||
                              process.env.REACT_APP_SKY === "true") &&
                            e.button === 0
                          ) {
                            previewGraphic("LTREDCARD", {
                              player,
                              cardType: 1,
                              team: team,
                              game,
                              show_number:
                                window.ENV?.REACT_APP_SKY !== "true" &&
                                process.env.REACT_APP_SKY !== "true"
                                  ? false
                                  : true,
                            });
                          } else {
                            previewGraphic("Card", {
                              player,
                              cardType: 3,
                              team: team,
                              game,
                            });
                          }
                          updateGame({
                            id: game._id,
                            lineup: newLineup,
                          });
                        }}
                      >
                        <RedCard />
                      </Card>
                    </Cards>
                  )}
                  {player?.squad && (
                    <>
                      <SubIcon
                        on={
                          player?.sub_order === 1 &&
                          player?.order > number_payers
                        }
                        off={
                          player?.sub_order === 1 &&
                          player?.order <= number_payers
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          let newLineup = [...lineup];

                          newLineup = newLineup.map((item) => {
                            return {
                              ...item,
                              squad: item.squad._id,
                            };
                          });

                          switch (e.button) {
                            case 0:
                              let off = player.order <= number_payers;

                              newLineup = newLineup.map((item) => {
                                if (off) {
                                  if (
                                    item.order <= number_payers &&
                                    item.sub_order === 1
                                  ) {
                                    return {
                                      ...item,
                                      sub_order: null,
                                    };
                                  }
                                } else if (
                                  item.order > number_payers &&
                                  item.sub_order === 1
                                ) {
                                  return {
                                    ...item,
                                    sub_order: null,
                                  };
                                }
                                return item;
                              });
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).sub_order = 1;

                              break;
                            case 2:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).sub_order = null;
                              break;
                            default:
                              break;
                          }

                          if (game.home_team._id === team._id) {
                            newLineup = {
                              home: newLineup,
                              away: game.lineup.away,
                            };
                          } else if (game.away_team._id === team._id) {
                            newLineup = {
                              away: newLineup,
                              home: game.lineup.home,
                            };
                          }

                          updateGame({
                            id: game._id,
                            lineup: newLineup,
                          });
                          setRightBarView(1);
                        }}
                      >
                        <IoIosGitCompare></IoIosGitCompare>
                      </SubIcon>
                      <SubIcon
                        on={
                          player?.sub_order === 2 &&
                          player?.order > number_payers
                        }
                        off={
                          player?.sub_order === 2 &&
                          player?.order <= number_payers
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          let newLineup = [...lineup];

                          newLineup = newLineup.map((item) => {
                            return {
                              ...item,
                              squad: item.squad._id,
                            };
                          });

                          switch (e.button) {
                            case 0:
                              let off = player.order <= number_payers;

                              newLineup = newLineup.map((item) => {
                                if (off) {
                                  if (
                                    item.order <= number_payers &&
                                    item.sub_order === 2
                                  ) {
                                    return {
                                      ...item,
                                      sub_order: null,
                                    };
                                  }
                                } else if (
                                  item.order > number_payers &&
                                  item.sub_order === 2
                                ) {
                                  return {
                                    ...item,
                                    sub_order: null,
                                  };
                                }
                                return item;
                              });
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).sub_order = 2;

                              break;
                            case 2:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).sub_order = null;
                              break;
                            default:
                              break;
                          }

                          if (game.home_team._id === team._id) {
                            newLineup = {
                              home: newLineup,
                              away: game.lineup.away,
                            };
                          } else if (game.away_team._id === team._id) {
                            newLineup = {
                              away: newLineup,
                              home: game.lineup.home,
                            };
                          }

                          updateGame({
                            id: game._id,
                            lineup: newLineup,
                          });
                          setRightBarView(1);
                        }}
                      >
                        <IoIosGitCompare></IoIosGitCompare>
                      </SubIcon>
                      <SubIcon
                        on={
                          player?.sub_order === 3 &&
                          player?.order > number_payers
                        }
                        off={
                          player?.sub_order === 3 &&
                          player?.order <= number_payers
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          let newLineup = [...lineup];

                          newLineup = newLineup.map((item) => {
                            return {
                              ...item,
                              squad: item.squad._id,
                            };
                          });

                          switch (e.button) {
                            case 0:
                              let off = player.order <= number_payers;

                              newLineup = newLineup.map((item) => {
                                if (off) {
                                  if (
                                    item.order <= number_payers &&
                                    item.sub_order === 3
                                  ) {
                                    return {
                                      ...item,
                                      sub_order: null,
                                    };
                                  }
                                } else if (
                                  item.order > number_payers &&
                                  item.sub_order === 3
                                ) {
                                  return {
                                    ...item,
                                    sub_order: null,
                                  };
                                }
                                return item;
                              });
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).sub_order = 3;

                              break;
                            case 2:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).sub_order = null;
                              break;
                            default:
                              break;
                          }

                          if (game.home_team._id === team._id) {
                            newLineup = {
                              home: newLineup,
                              away: game.lineup.away,
                            };
                          } else if (game.away_team._id === team._id) {
                            newLineup = {
                              away: newLineup,
                              home: game.lineup.home,
                            };
                          }

                          updateGame({
                            id: game._id,
                            lineup: newLineup,
                          });
                          setRightBarView(1);
                        }}
                      >
                        <IoIosGitCompare></IoIosGitCompare>
                      </SubIcon>
                    </>
                  )}
                </Row>
              </>
            );
          })}
      </Team>
      <Row fade noHover>
        <ShirtNumber></ShirtNumber>
        <Name fade>
          <FirstName></FirstName>
        </Name>
      </Row>
      {manager && (
        <Manager
          player={manager}
          selectedPlayer={selectedPlayer}
          selectPlayer={(player) => {
            selectPlayer(player);
          }}
          countries={countries}
          game={game}
          update={update}
          sky={sky}
          team={team}
          team_type={team_type}
          lineup={lineup}
        />
      )}
    </Main>
  );
}

function Manager({
  player,
  selectedPlayer,
  selectPlayer,
  previewGraphic,
  lineup,
  game,
  update,
  countries,
  sky,
  team,
  team_type,
}) {
  return (
    <Row
      clickable
      onClick={() => {
        if (!selectedPlayer || selectedPlayer._id !== player.player?._id) {
          selectPlayer({ ...player, ...player?.player });
        } else {
          selectPlayer(null);
        }
      }}
    >
      <ShirtNumber> </ShirtNumber>
      <BeenSubbed></BeenSubbed>
      <Name
        selected={selectedPlayer && selectedPlayer._id === player.player?._id}
      >
        <FirstName>{player.player?.first_name}</FirstName>

        <LastName padLeft={player.player?.first_name}>
          {player.player?.last_name} ({player.player?.title})
        </LastName>
        <span
          style={{
            fontSize: ".7em",
            fontWeight: "100",
            alignSelf: "flex-end",
            marginLeft: ".5em",
          }}
        >
          {player?.player?.nationality
            ? player?.player?.nationality?.code ||
              player?.player?.nationality?.opta_code
            : ""}
        </span>
      </Name>
      <Right></Right>
    </Row>
  );
}

function SinBin({ options, animateGraphic, animateOffGraphic }) {
  const [diff, setDiff] = useState();
  useEffect(() => {
    let interval;
    let game_mins =
      options?.clockType === "manual"
        ? parseInt(document.getElementById("manual_minutes")?.innerText)
        : parseInt(document.getElementById("stadium_minutes")?.innerText);

    let game_secs =
      options?.clockType === "manual"
        ? parseInt(document.getElementById("manual_seconds")?.innerText)
        : parseInt(document.getElementById("stadium_seconds")?.innerText);

    let duration = moment.duration({
      minutes: game_mins,
      seconds: game_secs,
    });

    let duration_2 = moment.duration({
      minutes: options?.player?.sinbins?.at(-1)?.game_time_minutes,
      seconds: options?.player?.sinbins?.at(-1)?.game_time_seconds,
    });

    let total_sinbin = moment.duration({ minutes: 10 });

    let diff = total_sinbin.subtract(duration.subtract(duration_2));
    setDiff(diff);
    interval = setInterval(() => {
      let game_mins =
        options?.clockType === "manual"
          ? parseInt(document.getElementById("manual_minutes")?.innerText)
          : parseInt(document.getElementById("stadium_minutes")?.innerText);

      let game_secs =
        options?.clockType === "manual"
          ? parseInt(document.getElementById("manual_seconds")?.innerText)
          : parseInt(document.getElementById("stadium_seconds")?.innerText);

      let duration = moment.duration({
        minutes: game_mins,
        seconds: game_secs,
      });

      let duration_2 = moment.duration({
        minutes: options?.player?.sinbins?.at(-1)?.game_time_minutes,
        seconds: options?.player?.sinbins?.at(-1)?.game_time_seconds,
      });

      let total_sinbin = moment.duration({ minutes: 10 });

      let diff = total_sinbin.subtract(duration.subtract(duration_2));
      setDiff(diff);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [options]);
  return (
    diff?.minutes() > -1 &&
    diff?.seconds() > -30 && (
      <SinBinClock
        diff={diff}
        options={options}
        animateGraphic={animateGraphic}
        animateOffGraphic={animateOffGraphic}
      />
    )
  );
}

function SinBinClock({ diff, options, animateGraphic, animateOffGraphic }) {
  return (
    <SBRow>
      <span>
        SB: {Math.max(diff?.minutes(), 0)}:
        {Math.max(diff?.seconds(), 0)?.toString()?.padStart(2, "0")}
      </span>
      <SBButton
        green
        onClick={() => {
          animateGraphic("LTSINBIN", {
            player: options?.player,
            team: options?.team,
            game: options?.game,
            clockType: options?.clockType,
          });
        }}
      >
        On
      </SBButton>
      <SBButton
        red
        onClick={() => {
          animateOffGraphic("LTSINBIN", {
            team: options?.team,
            game: options?.game,
          });
        }}
      >
        Off
      </SBButton>
    </SBRow>
  );
}
