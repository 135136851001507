import React from "react";
import styled from "styled-components";
import { ScrollBar } from "../../Theme/Hyper";
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const GraphicsList = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  max-height: 560px;
  overflow-y: auto;
  width: 100%;
  align-items: center;
`;
function Teams({
  game,
  period,
  teams,
  players,
  homeManager,
  awayManager,
  previewGraphic,
  homeSquad,
  awaySquad,
}) {
  return (
    <GraphicsList>
      {(process.env.REACT_APP_SKY === "true" ||
        window.ENV?.REACT_APP_SKY === "true") && (
        <GraphicButton
          onClick={async () => {
            //FF HOME
            await previewGraphic("FormationHeads", {
              game: game,
              teams: teams,
              players: players,
              team: game.home_team,
              squads: game.lineup?.home,
              formation: game.home_formation,
              manager: homeManager,
              squad: homeSquad,
            });
            //FF AWAY
            await previewGraphic("FormationHeads", {
              game: game,
              teams: teams,
              players: players,
              team: game.away_team,
              squads: game.lineup?.away,
              formation: game.away_formation,
              manager: awayManager,
              squad: awaySquad,
            });
            //HOME SOF
            await previewGraphic("FormationSOF", {
              game: game,
              teams: teams,
              players: players,
              team: game.home_team,
              squads: game.lineup?.home,
              formation: game.home_formation,
              squad: homeSquad,
            });
            //AWAY SOF
            await previewGraphic("FormationSOF", {
              game: game,
              teams: teams,
              players: players,
              team: game.away_team,
              squads: game.lineup?.away,
              formation: game.away_formation,
              squad: awaySquad,
            });

            //HOME CRAWL
            await previewGraphic("TeamCrawl", {
              game: game,
              teams: teams,
              players: players,
              team: game.home_team,
              squads: game.lineup?.home,
              formation: game.home_formation,
              squad: homeSquad,
            });

            //AWAY CRAWL
            await previewGraphic("TeamCrawl", {
              game: game,
              teams: teams,
              players: players,
              team: game.away_team,
              squads: game.lineup?.away,
              formation: game.away_formation,
              squad: awaySquad,
            });
            //HOME BOTTOM FLIPPER
            await previewGraphic("TeamBottomBar", {
              game: game,
              teams: teams,
              players: players,
              team: game.home_team,
              squads: game.lineup?.home,
              formation: game.home_formation,
              squad: homeSquad,
            });

            //AWAY BOTTOM FLIPPER
            await previewGraphic("TeamBottomBar", {
              game: game,
              teams: teams,
              players: players,
              team: game.away_team,
              squads: game.lineup?.away,
              formation: game.away_formation,
              squad: awaySquad,
            });
          }}
        >
          Build All
        </GraphicButton>
      )}
      {(window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true") && (
        <GraphicButton
          onClick={() => {
            previewGraphic("HeroFormation", {
              game: game,
              homeLineup: game.lineup?.home,
              awayLineup: game.lineup?.away,
              homeManager: homeManager,
              awayManager: awayManager,
            });
          }}
        >
          Hero Formations
        </GraphicButton>
      )}
      {(game?.competition?.name === "Women's Super League" ||
        game?.competition?.name === "Super League" ||
        game?.competition?.name === "Super League OMO" ||
        game?.competition?.name === "World Club Challenge" ||
        game?.competition?.name === "URC" ||
        window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true") &&
        !period?.kick_off &&
        period?.number === 1 && (
          <GraphicButton
            onClick={() => {
              previewGraphic("FormationHeads", {
                game: game,
                teams: teams,
                players: players,
                team: game.home_team,
                squads: game.lineup?.home,
                formation: game.home_formation,
                manager: homeManager,
                squad: homeSquad,
              });
            }}
          >
            Home Team FF Formation (42)
          </GraphicButton>
        )}

      {(game?.competition?.name === "Women's Super League" ||
        game?.competition?.name === "Super League" ||
        game?.competition?.name === "Super League OMO" ||
        game?.competition?.name === "World Club Challenge" ||
        game?.competition?.name === "URC" ||
        window.ENV?.REACT_APP_SKY === "true" ||
        process.env.REACT_APP_SKY === "true") &&
        !period?.kick_off &&
        period?.number === 1 && (
          <GraphicButton
            onClick={() => {
              previewGraphic("FormationHeads", {
                game: game,
                teams: teams,
                players: players,
                team: game.away_team,
                squads: game.lineup?.away,
                formation: game.away_formation,
                manager: awayManager,
                squad: awaySquad,
              });
            }}
          >
            Away Team FF Formation (44)
          </GraphicButton>
        )}
      {!period?.kick_off &&
        period?.number === 1 &&
        (process.env.REACT_APP_SKY === "true" ||
          window.ENV?.REACT_APP_SKY === "true") && (
          <GraphicButton
            onClick={() => {
              previewGraphic("FormationSOF", {
                game: game,
                teams: teams,
                players: players,
                team: game.home_team,
                squads: game.lineup?.home,
                formation: game.home_formation,
                manager: awayManager,
                squad: homeSquad,
              });
            }}
          >
            Home Team SOF (48)
          </GraphicButton>
        )}

      {!period?.kick_off &&
        period?.number === 1 &&
        (process.env.REACT_APP_SKY === "true" ||
          window.ENV?.REACT_APP_SKY === "true") && (
          <GraphicButton
            onClick={() => {
              previewGraphic("FormationSOF", {
                game: game,
                teams: teams,
                players: players,
                team: game.away_team,
                squads: game.lineup?.away,
                formation: game.away_formation,
                manager: awayManager,
                squad: awaySquad,
              });
            }}
          >
            Away Team SOF (49)
          </GraphicButton>
        )}
      {(process.env.REACT_APP_SKY === "true" ||
        window.ENV?.REACT_APP_SKY === "true") && (
        <GraphicButton
          onClick={() => {
            previewGraphic("TeamCrawl", {
              game: game,
              teams: teams,
              players: players,
              team: game.home_team,
              squads: game.lineup?.home,
              formation: game.home_formation,
              manager: awayManager,
              squad: homeSquad,
            });
          }}
        >
          Home Team Crawl (6950)
        </GraphicButton>
      )}
      {(process.env.REACT_APP_SKY === "true" ||
        window.ENV?.REACT_APP_SKY === "true") && (
        <GraphicButton
          onClick={() => {
            previewGraphic("TeamCrawl", {
              game: game,
              teams: teams,
              players: players,
              team: game.away_team,
              squads: game.lineup?.away,
              formation: game.away_formation,
              manager: awayManager,
              squad: awaySquad,
            });
          }}
        >
          Away Team Crawl (6950)
        </GraphicButton>
      )}

      {!period?.kick_off &&
        period?.number === 1 &&
        (process.env.REACT_APP_SKY === "true" ||
          window.ENV?.REACT_APP_SKY === "true") &&
        game?.competition?.variables?.number_of_players_on_field > 5 && (
          <GraphicButton
            onClick={() => {
              previewGraphic("TeamBottomBar", {
                game: game,
                teams: teams,
                players: players,
                team: game.home_team,
                squads: game.lineup?.home,
                formation: game.home_formation,
                manager: awayManager,
                squad: homeSquad,
              });
            }}
          >
            Home Bottom Bar Flippers (6800+)
          </GraphicButton>
        )}
      {!period?.kick_off &&
        period?.number === 1 &&
        (process.env.REACT_APP_SKY === "true" ||
          window.ENV?.REACT_APP_SKY === "true") &&
        game?.competition?.variables?.number_of_players_on_field > 5 && (
          <GraphicButton
            onClick={() => {
              previewGraphic("TeamBottomBar", {
                game: game,
                teams: teams,
                players: players,
                team: game.away_team,
                squads: game.lineup?.away,
                formation: game.away_formation,
                manager: awayManager,
                squad: awaySquad,
              });
            }}
          >
            Away Bottom Bar Flippers (6810+)
          </GraphicButton>
        )}
      <GraphicButton
        onClick={() => {
          previewGraphic("OnBench", {
            game: game,
            subs: game.lineup?.home?.filter((p) => p.order > 13),
            team: game?.home_team,
          });
        }}
      >
        CB - Home Bench
      </GraphicButton>
      <GraphicButton
        onClick={() => {
          previewGraphic("OnBench", {
            game: game,
            subs: game.lineup?.away?.filter((p) => p.order > 13),
            team: game?.away_team,
          });
        }}
      >
        CB - Away Bench
      </GraphicButton>
    </GraphicsList>
  );
}

export default Teams;
