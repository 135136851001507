import React, { useState, useEffect, useContext, useCallback } from "react";
import useLocalStorage from "../Hooks/useLocalStorage";
import parser from "fast-xml-parser";
import {
  ApolloClient,
  createHttpLink,
  getApolloContext,
  gql,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
var options = {
  attributeNamePrefix: "",
  attrNodeName: "attr", //default is 'false'
  textNodeName: "#text",
  ignoreAttributes: false,
  ignoreNameSpace: false,
  allowBooleanAttributes: true,
  parseNodeValue: false,
  parseAttributeValue: false,
  trimValues: false,
  cdataTagName: "__cdata", //default is 'false'
  cdataPositionChar: "\\c",
  parseTrueNumberOnly: false,
  arrayMode: false, //"strict"
  stopNodes: ["parse-me-as-string"],
};
var XMLParser = parser.j2xParser;
const toXML = new XMLParser(options);

const GET_ENGINES = gql`
  query GetEngines {
    enginesForUser {
      name
      _id
      key
    }
  }
`;

const ControlContext = React.createContext([{}, () => {}]);
window.scenesOnAir = [];

window.control_ws = null;
let command_id = 0;
let commands = [];
let show_ID = "";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_PROJECT_SERVER ||
      process.env.REACT_APP_PROJECT_SERVER) + "/graphql",
});

const project_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const ControlProvider = (props) => {
  const { client } = useContext(getApolloContext());
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const [scenesOnAir, setScenesOnAir] = useState([]);
  const [usersOnAir, setUserOnAir] = useState([]);
  const [themeName, setThemeName] = useState("rugby");
  const [hyperClock, setHyperClock] = useLocalStorage(
    "hyperClock",
    (window.location.protocol.indexOf("https") > -1 ? "wss" : "ws") +
      "://clk1.hyper.live"
  );

  const [engines, setEngines] = useState([]);
  const [selectedEngine, setSelectedEngine] = useLocalStorage(
    "rugby-selected-engine",
    []
  );

  useEffect(() => {
    if (
      window.ENV?.REACT_APP_SKY === "true" ||
      process.env.REACT_APP_SKY === "true"
    ) {
      window.engines = [
        { name: "BIG SCREEN 1", key: "BIG_SCREEN_1" },
        { name: "BIG SCREEN 2", key: "BIG_SCREEN_2" },
        { name: "BIG SCREEN 3", key: "BIG_SCREEN_3" },
      ];
      setEngines([
        { name: "BIG SCREEN 1", key: "BIG_SCREEN_1" },
        { name: "BIG SCREEN 2", key: "BIG_SCREEN_2" },
        { name: "BIG SCREEN 3", key: "BIG_SCREEN_3" },
      ]);
    } else {
      project_client
        .query({
          query: GET_ENGINES,
        })
        .then((response) => {
          window.engines = response.data.enginesForUser.map((e) => ({
            ...e,
          }));
          setEngines(
            response.data.enginesForUser.map((e) => ({
              ...e,
            }))
          );
        })
        .catch((err) => console.error(err));
    }
  }, []);

  useEffect(() => {
    window.selectedEngine = selectedEngine;
  }, [selectedEngine]);

  const animateOff = useCallback(
    (name, data) => {
      animateOffLocal(name, data);
    },
    [scenesOnAir, setScenesOnAir, ws, sendData]
  );

  const animate = useCallback(
    (name, data) => {
      animateLocal(name, data);
    },
    [scenesOnAir, setScenesOnAir, ws, sendData]
  );

  useEffect(() => {
    window.id = "rugby";
    connect();
  }, []);

  function connect() {
    console.log("Rugby Control connecting to server");
    if (window.command_server) {
      window.control_ws = new WebSocket(window.command_server);

      setWS(window.control_ws);
    }
  }

  useEffect(() => {
    if (window.control_ws) {
      let timeout;
      window.control_ws.onopen = () => {
        setWSStatus(1);
        console.log("Rugby Control on open");
        clearInterval(window.keep_alive);
        window.keep_alive = setInterval(() => {
          window.control_ws.send(
            JSON.stringify({
              keep_alive: Date.now(),
              group: window.id,
            })
          );
        }, 20000);
        sendData({
          group: window.id,
        });
      };
      window.control_ws.onmessage = (data) => {
        console.log("Rugby Control on message");
        try {
          let obj = JSON.parse(data.data);

          if (obj) {
            if (obj.command_id && !obj.from_control) {
              let command = commands.find(
                (command) => command.id === obj.command_id
              );

              let xml = parser.parse(obj?.response, options);
              clearTimeout(command.timeout);
              command?.resolve(xml);
              commands = commands.filter(
                (command) => command.id !== obj.command_id
              );
            }
          }
        } catch (err) {
          console.error(err);
        }
      };
      window.control_ws.onerror = (err) => {
        console.log("Rugby Control on message", err);
        ws.close();
      };
      window.control_ws.onclose = (data) => {
        setWSStatus(0);
        console.log("Rugby Control on close");
        clearTimeout(timeout);
        clearInterval(window.keep_alive);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  function sendData(data, from_control = true) {
    try {
      if (window.control_ws) {
        data.user = {
          id: window.id,
        };

        data.group = localStorage.getItem("user-id");
        if (from_control) {
          data.app = "rugby";
        }
        data.engine =
          window.engines?.find((e) => e.key === window.selectedEngine)?.key ||
          window.engines?.[0]?.key;

        window.control_ws.send(JSON.stringify({ ...data, from_control }));
      }
    } catch (err) {}
  }

  function updateGlobals(theme) {
    sendData({
      action: "globals",
      group: window.id,
      data: {
        globals: theme.data,
      },
    });
  }

  function preview(name, frame, data, timline) {
    sendData({
      action: "preview",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        frame: frame,
        timeline: timline || "IN",
        data: data,
      },
    });
  }

  function clearPreview(name) {
    sendData({
      action: "CLEAR_PREVIEW",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
      },
    });
  }

  function clear(group) {
    setScenesOnAir([]);
    sendData({
      action: "CLEAR",
      group: "",
      engine: window.engines?.[0]?.key,
    });
  }

  function animateLocal(name, data, pageNumber) {
    clearPreview(name);

    sendData({
      action: "animate",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        timeline: "IN",
        data: data,
      },
    });
  }

  function update(name, data) {
    sendData(
      {
        action: "update",
        group: window.id,
        engine:
          window.engines?.find((e) => e.key === window.selectedEngine)?.key ||
          window.engines?.[0]?.key,
        data: {
          scene: name,
          data: data,
        },
      },
      name === "BS-Clock" ? false : true ///bodge to update BS-Clock scene directly for Sky not through data mapping
    );
  }

  function triggerAnimation(name, data, animation) {
    sendData({
      action: "animate",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        timeline: animation,
        data: updateData(data),
      },
    });
  }

  function animateOffLocal(name, data) {
    sendData({
      action: "animate",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        timeline: "OUT",
        data: [],
      },
    });
  }

  function updateData(data) {
    if (data && data.length > 0) {
      return data.map((item) => {
        if (item.type === "TEXT") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              text: item.text,
              colour: item.colour,
              fontSize: item.fontSize || item.style._fontSize,
              position: {
                x: item.x,
                y: item.y,
              },
            },
          };
        } else if (item.type === "IMAGE") {
          if (item.name === "FG_IMAGE") {
            return {
              name: item.name,
              value: {
                visible: item.visible,
                image: item.image || item.src,
                width: item.width,
                height: item.height,
              },
            };
          } else {
            return {
              name: item.name,
              value: {
                visible: item.visible,
                image: item.image || item.src,
                position: {
                  x: item.x,
                  y: item.y,
                },
                width: item.width,
                height: item.height,
              },
            };
          }
        } else if (item.type === "GROUP") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              position: {
                x: item.x,
                y: item.y,
              },
            },
          };
        }
      });
    }
    return [];
  }

  function connectEngine(engine) {
    sendData({
      type: engine.status ? "connect-engine" : "disconnect-engine",
      engine: engine.name,
    });
  }

  return (
    <ControlContext.Provider
      value={{
        sendData,
        preview,
        animate,
        animateOff,
        scenesOnAir,
        clearPreview,
        setThemeName,
        wsStatus,
        clear,
        triggerAnimation,
        update,
        connectEngine,
        usersOnAir,
        hyperClock,
        setHyperClock,
        engines,
        selectedEngine,
        setSelectedEngine,
      }}
    >
      {props.children}
    </ControlContext.Provider>
  );
};

export { ControlContext, ControlProvider };
